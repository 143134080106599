<template>
  <v-app>
    <!-- Navigation Drawer -->
    <v-navigation-drawer
      app
      :persistent="drawer"
      mini-variant-width="80px"
      :width="drawer ? 256 : 80"
      :mini-variant="!drawer"
    >
      <StratasNavBar  @toggle-drawer = toggleDrawer />
    </v-navigation-drawer>

    <!-- App Bar -->
    <v-app-bar app :style="appBarStyle">
      <nav-bar />
    </v-app-bar>

    <!-- Main Content -->
    <v-main :style="mainStyle">
      <v-row>
          <v-col cols="12">
            <router-view > </router-view>
            <NoAccessDialog></NoAccessDialog>
          </v-col>
        </v-row>

        <!-- Footer -->
        <v-footer color="#fff" class="str-footer px-0 pt-0 mt-0">
          <footer class="str-footer mt-0" style="width: 100%;">
            <p>
              &copy; {{ determineDate() }} Randall Reilly Talent. All rights reserved. |
                <a href="https://randallreilly.com/legal/privacy-policy/" target="_blank">Privacy Policy</a> |
                <a href="https://randallreilly.com/legal/website-terms-and-conditions/" target="_blank">Terms of Use</a> |
                <a href="https://randallreilly.com/legal/dsar/" target="_blank">Do Not Sell or Share My Personal Information</a>
            </p>
        </footer>
      </v-footer>

    </v-main>

  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import identifyVisitor from '../../helpers/html-support-setup';
import NavBar from '../Navbar/NavBar.vue';
import StratasNavBar from '../Navbar/NavBar-Stratas.vue';
import NoAccessDialog from '../Dialogs/NoAccessDialog.vue';

export default {
  name: 'MainWrapper',
  components: {
    NavBar,
    StratasNavBar,
    NoAccessDialog,
  },
  props: {
    drawerItems: { type: Array, required: true },
  },
  data: () => ({
    menuVisible: false,
    height: '',
    drawer: true,
    isMiniVariant: false, // Controls whether the drawer is collapsed (mini-variant)
  }),
  mounted() {
    const savedDrawerState = localStorage.getItem('openSidebar');
    if (savedDrawerState !== null) {
      this.drawer = JSON.parse(savedDrawerState);
      this.isMiniVariant = !this.drawer;
    }
    // eslint-disable-next-line max-len
    identifyVisitor({ userEmail: this.userEmail, userRoles: this.userRoles, userSsoId: this.userSsoId });
    if (this.$chameleon && this.userEmail) {
      this.$chameleon.identify(this.userSsoId, {
        uid_hash: this.chameleonId,
        email: this.userEmail,
        roles: this.userRoles,
        project: 'Strategize',
      });
    }
  },
  computed: {
    mainStyle() {
      // Adjust the padding of the main content based on the drawer's width
      return {
        padding: this.drawer ? '0 0 0 256px' : '0 0 0 80px', // Adjust left padding depending on the drawer width
      };
    },
    appBarStyle() {
      // Adjust the margin-left of the app bar to shift with the drawer's width
      return {
        marginLeft: !this.drawer ? '-176px' : '0px', // Shifting app bar based on drawer width
      };
    },
    currentPage() {
      return this.$route.name.toLowerCase();
    },
    ...mapGetters({
      userRoles: 'auth/getUserRoles',
      userEmail: 'auth/getUserEmail',
      userSsoId: 'auth/getUserSsoId',
      chameleonId: 'auth/getChameleonId',
    }),
  },
  methods: {
    toggleDrawer() {
      this.drawer = !this.drawer;
      this.isMiniVariant = !this.drawer;
      localStorage.setItem('openSidebar', JSON.stringify(this.drawer)); // Persist state in localStorage
    },
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
    },
    determineDate() {
      return new Date().getFullYear();
    },
    isPathActive(item) {
      return this.$route.name === item;
    },
    setNextPage(page) {
      let pageValue = {
        linkId: page.title,
      };
      pageValue = JSON.stringify(pageValue);
      sessionStorage.setItem('lastPageViewed', pageValue);
    },
  },
  watch: {
    drawer(newValue) {
      // When 'drawer' changes, update localStorage.
      localStorage.setItem('openSidebar', JSON.stringify(newValue));
    },
  },
};
</script>

<style lang="scss" scoped>
.v-navigation-drawer--mini-variant:hover {
  overflow: inherit;
}
footer a {
  text-decoration: none;
  font-weight: 400;
}
.cls-1 {
  fill: #5e883c;
}

.cls-1, .cls-2, .cls-3 {
  stroke-width: 0px;
}

.cls-2 {
  fill: #76aa41;
}

.cls-3 {
  fill: #f5f3f1;
}
.maps .v-main {
  position: fixed;
  min-width: 100%;
}
.maps footer {
  bottom: 0;
  position: absolute;
}
.drawer-icon {
  color: #878787 !important;
}
.drawer-icon-text {
  color: #878787 !important;
  font-weight: 700;
}
.tooltip-style {
  background-color: #000000;
  color: #efefef;
  font-size: 0.8rem;
  margin-left: 10% !important;
}
.stratas-footer-content {
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  height: 46px;
  color: #555;
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 12px;
  z-index: 10;
}

.stratas-footer-content{
  display: flex;
  align-items: center;
  text-align: center;
}

.navigation-sidebar {
  .v-list-item {
    padding: 0 !important;
  }

  .custom-hover {
    background-color: #F7F8FA !important;

    .v-icon {
      color: #538703 !important;
    }
  }

  .v-list-item--link:before {
    background-color: transparent !important;
  }

  .active-icon {
    background-color: transparent !important;

    .v-icon {
      color: #538703 !important;
    }
  }

  rounded-border {
    position: relative;
    overflow: hidden;
    background-color: transparent;
  }

  .rounded-border::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 5px;
    background-color: #538703;
    border-radius: 10%;
    margin: auto 0;
  }

  .v-btn--icon.v-size--default {
    color: #4A4A4A !important;

    .v-icon {
      height: 48px !important;
      font-size: 32px !important;
      margin-left: 35% !important;
    }
  }
}
.locations, .trends, .driver, .mechanic {
  .v-tooltip__content--fixed {
    margin-left: 1% !important;
  }
}
</style>
