// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import 'vue-toast-notification/dist/theme-sugar.css';
import VueEllipseProgress from 'vue-ellipse-progress';
import VueCookies from 'vue-cookies';
import Hotjar from 'vue-hotjar';
import Vue from 'vue';
import Vuex from 'vuex';
import VueToast from 'vue-toast-notification';
import ActionCableVue from 'actioncable-vue';
import * as Sentry from '@sentry/vue';
import { captureConsoleIntegration } from '@sentry/integrations';
import App from './App.vue';
import routes from './router';
import store from './store/index';
import './main.scss';
import { AuthPlugin } from './services/AuthService';

// Add vuetify
import vuetify from './plugins/Vuetify';
import JobPostings from './components/Competition/JobPostings.vue';
import Trends from './components/Market/Trends.vue';
import Locations from './components/Locations/Locations.vue';
import Admin from './components/Pages/Admin.vue';
import RecruitingDifficulty from './components/Locations/Maps/RecruitingDifficulty.vue';
import Wages from './components/Wages/Wages.vue';

const chameleon = require('@chamaeleonidae/chmln');

/* chameleon.io NPM script */
chameleon.init('SjxrciyI4zUbmUZ0TwwL65Xe1v7zFBVJWP3gj3ZUtl6OY-1QvC20-EAMzn2JNrjQMXVOc', { fastUrl: 'https://fast.chameleon.io/' });
// Heap Analytics Tracking
Vue.prototype.$heap = window.heap;
Vue.prototype.$chameleon = chameleon;

Vue.use(VueEllipseProgress);

Vue.use(AuthPlugin, {
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  redirectUri: process.env.VUE_APP_AUTH0_REDIRECT_URL,
  audience: process.env.VUE_APP_AUTH0_AUDIENCE,
});

// import vue-cookies
Vue.use(VueCookies);
Vue.$cookies.config('1d');

// import vue-toast-notifications
Vue.use(VueToast);

Vue.config.productionTip = false;
Vue.use(Vuex);

Vue.use(Hotjar, {
  id: '2596552', // Hotjar Site ID
  isProduction: process.env.NODE_ENV === 'production',
});

Vue.use(ActionCableVue, {
  connectionUrl: process.env.VUE_APP_WSS_URL,
  connectImmediately: true,
});

Vue.use(Sentry);

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    // Used to specify environment in Sentry
    // environment: 'development',
    sampleRate: 0.25,
    enableTracing: true,
    trackComponents: [Trends, Locations, RecruitingDifficulty, JobPostings, Admin, Wages],
    integrations: [
      Sentry.browserTracingIntegration({ routes }),
      captureConsoleIntegration(),
    ],
  });
  // Save the original fetch function
  const originalFetch = window.fetch;

  // Redefine fetch with your custom implementation
  window.fetch = async function (input, init) {
    Sentry.addBreadcrumb({
      category: 'fetch',
      message: 'Fetch request',
      data: {
        url: input,
        method: init?.method || 'GET',
        body: init?.body || null,
      },
    });
    return originalFetch(input, init);
  };
}

const mixin = {
  computed: {
    isAuthenticated() {
      return this.$store.state.auth.isAuthenticated;
    },
  },
};

/* eslint-disable no-new */
new Vue({
  el: '#app',
  mixins: [mixin],
  router: routes,
  store,
  vuetify,
  components: { App },
  template: '<App/>',
});
