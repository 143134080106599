<!-- eslint-disable max-len -->
<template>
  <v-app>
    <div class="compitition-page-content">
      <!-- <v-col
        cols=2
        class="p-0 padding-none"
        v-bind:style="[isCollapsed ? {'margin-left': '-20.5%'} : '']"
      >
        <v-btn
          tile
          elevation="0"
          @click="toggleFilter"
          v-bind:style="[isCollapsed ? {left: '224px'} : '']"
          class="toggle-filter-btn"
          :class="[isCollapsed ? 'collapsed-sidebar-btn' : 'v-btn--icon expanded-sidebar-btn']"
        >
          <v-icon
            class="grey--text text--darken-3"
            v-bind:style="[isCollapsed ? {transform: 'rotate(180deg)'} : '']">mdi-chevron-down
          </v-icon>
          <span
            class="filters-span"
            v-bind:style="[isCollapsed ? {display: 'inline-block'} : {display: 'none'}]">
            Filters
          </span>
        </v-btn>
      </v-col> -->
      <v-col :cols="colSize">
        <div class="main-container">
          <div class="top-section mb-6">
           <v-col cols="12" class="d-flex">
            <v-btn
                id="toggle-filter-sb"
                @click="toggleFilter()"
                elevation="0"
                class="ml-auto"
                color="#ededed"
             >
              <v-icon
                class=""
                size="14"
                color="#787878"
                ripple="false"
              >mdi-tune-vertical</v-icon> Filters
             </v-btn>
           </v-col>

            <!-- <button id="toggle-filter-sb" class="btn btn-default tw-inline-block tw-mr-[10px]"> <i class="fa fa-filter text-ltgray"></i></button> -->
            <v-navigation-drawer
                v-model="isCollapsed"
                app
                right
                temporary
                width="300"
                hide-overlay
                style="z-index: 1"
                rounded="false"
            >
            <div class="search-toolbar">
              <v-btn @click="isCollapsed = false" class="filters-close-btn" color="#ededed" elevation="0" absolute fab small>
                <v-icon color="#787878">mdi-close</v-icon>
              </v-btn>
              <div class="clear-filters">
                <span @click="removeAllFilters" style="cursor: pointer">x Clear All Filters</span>
              </div>
                <h5 class="main-heading">Filters</h5>
                <div class="posting-date-filter">
                  <Accordion :leftArrowDropUp="false">
                    <template v-slot:title>
                      <h5 class="accordion-title">Timeframe</h5>
                    </template>
                    <div>
                      <div class="subtitle">Posting Date</div>
                      <div class="posting-date">
                        <v-radio-group id="posting-date-options" class="child" v-model="postingDateChosen">
                          <v-radio
                            v-for="postingDateOption in postingDateOptions"
                            :key="postingDateOption.months"
                            :value="postingDateOption"
                            @click="
                              changePostingDateFilter({
                                months: postingDateOption.months,
                                label: postingDateOption.label,
                              })
                            "
                            :label="postingDateOption.label"
                            :name="postingDateOption.label"
                            :color="'#699E00'"
                          >
                          </v-radio>
                        </v-radio-group>
                      </div>
                      <div class="calender-selection mt-2 job-postings-input-wrapper">
                        <PostingDateCalendar
                          :defaultRange="selectedTimeFrame"
                          @setDateRange="setCalendarDateRange"
                          ref="postingDateCalendar"
                        ></PostingDateCalendar>
                      </div>
                    </div>
                  </Accordion>
                </div>
                <div class="posting-driver-type">
                  <Accordion :leftArrowDropUp="true">
                    <template v-slot:title>
                      <h5 class="accordion-title">Job Type</h5>
                    </template>
                    <div>
                      <Accordion :leftArrowDropUp="true" class="with-include-exclude">
                        <template v-slot:title>
                          <div class="job-type-sub-heading">
                            <div class="subtitle">Posting Driver Type</div>
                          </div>
                        </template>
                        <v-checkbox
                          v-for="driverTypeOption in driverTypeOptions"
                          :key="driverTypeOption.name"
                          :label="driverTypeOption.name"
                          :name="driverTypeOption.name"
                          :input-value="driverTypeOption.checked"
                          @change="
                            changeJobType(
                              $event,
                              driverTypeOption.dbColumnName,
                              'driverTypeFilters',
                              driverTypeOption.name
                            )
                          "
                        />
                      </Accordion>
                      <Accordion
                        :leftArrowDropUp="true"
                        :expanded="false"
                        v-for="filterOption in accordionFilterOptions"
                        :key="filterOption.accordionTitle"
                        class="with-include-exclude"
                      >
                        <template v-slot:title>
                          <div class="job-type-sub-heading">
                            <div class="subtitle">{{ filterOption.accordionTitle }}</div>
                          </div>
                        </template>
                        <v-checkbox
                          v-for="radioButtonOption in filterOption.radioButtonOptions"
                          :key="radioButtonOption.name"
                          :label="radioButtonOption.name"
                          :name="radioButtonOption.name"
                          :input-value="radioButtonOption.checked"
                          @change="
                            changeJobType(
                              $event,
                              radioButtonOption.dbColumnName,
                              radioButtonOption.filterType,
                              radioButtonOption.name
                            )
                          "
                        />
                      </Accordion>
                    </div>
                  </Accordion>
                </div>
                <div class="company-filter">
                  <span class="label-area">Search by Company</span>
                  <v-col class="p-0">
                    <v-select
                      v-model="companySearched"
                      :items="determineCompanies"
                      @focus="handleDropdownToggle"
                      item-text="title"
                      ref="company-search-select"
                      :menu-props="{ top: true, offsetY: true, 'max-width': '350px' , 'min-width': '350px', overflow: true}"
                      item-value="value"
                      label="Search by Company"
                      multiple
                    >
                      <template v-slot:prepend-item>
                        <v-container class="static-search">
                          <!-- eslint-disable vuejs-accessibility/no-autofocus-->
                          <v-text-field
                            id="search_carriers"
                            ref="search_carriers"
                            :autofocus="true"
                            label="Search by Company"
                            append-icon="search"
                            v-model="typedLocationName"
                            @input="debounceFunction"
                          />
                          <!-- eslint-enable vuejs-accessibility/no-autofocus-->
                        </v-container>
                        <v-list-item
                          ripple
                          @mousedown.prevent
                          @click="handleSelectAll"
                          two-line
                        >
                          <v-list-item-action>
                            <v-icon>
                              {{ selectIcon }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              Select All
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template v-slot:append>
                        <v-progress-circular
                          v-if="autocompleteLoading"
                          indeterminate
                          size="20"
                          color="primary"
                        ></v-progress-circular>
                      </template>
                      <template v-slot:selection="" />
                    </v-select>
                  </v-col>
                  <div class="side-bar-run-search">
                    <v-tooltip top right v-model="animated.sideBar" content-class="warning-tool-tip">
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on.click">
                          <v-btn
                            @click="runSearch(false, 'sideBar')"
                            :class="{
                              'primary-run-search-button': !needsUpdate || initialLoad,
                              'update-run-search-button': needsUpdate && !initialLoad,
                              shake: animated.sideBar && !warningAcknowledged,
                            }"
                          >
                            {{ determineSearchButtonText }}
                          </v-btn>
                        </span>
                      </template>
                      <div>
                        <v-icon color="red">mdi-alert</v-icon>
                        <span style="color: black"
                          >Consider using our filters to narrow down your search to get meaningful
                          information.</span
                        >
                      </div>
                    </v-tooltip>
                  </div>
                </div>
              </div>
            </v-navigation-drawer>

            <v-row>
              <v-col class="pl-3 mb-3"><h2>Competition</h2></v-col>
            </v-row>
            <div class="location-search">
              <div class="location">
              <div class="location-search-area">
                <div class="location-search-radius">
                  <label for="competition_location_search">Location Search</label>
                  <div class="text-center">
                    <p @click="handleVisibleChange">Search by Radius</p>
                    <v-dialog
                      height="80vh"
                      width="75vw"
                      v-model="isVisible"
                      eager
                      persistent
                      @keydown.esc="isVisible = false"
                    >
                      <CountyMapModal
                        :isVisible="isVisible"
                        v-if="isVisible"
                        :defaultSelectedAreas="defaultSelectedAreas"
                        @area-removed="handleAreaRemoved"
                        @close="handleClose"
                        @accept="handleRadiusSelection"
                      />
                    </v-dialog>
                  </div>
                </div>
                <LocationListings
                :initial-location="{}"
                :focusOnLoad="true"
                :clearAfterSelection="true"
                searchFieldId="competition_location_search"
                placeholder="Search by state, metropolitan area, or county"
                @selected="handleLocationSelected"
                />
              </div>
              <div class="primary-run-search-button-wrapper">
                <v-tooltip top right v-model="animated.topBar" content-class="warning-tool-tip">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on.click">
                  <v-btn
                    :class="{
                    'primary-run-search-button': !needsUpdate || initialLoad,
                    'update-run-search-button': needsUpdate && !initialLoad,
                    shake: animated.topBar && !warningAcknowledged,
                    }"
                    class="primary-run-search-button"
                    @click="runSearch(false, 'topBar')"
                    >{{ determineSearchButtonText }}
                  </v-btn>
                  </span>
                </template>
                <div>
                  <v-icon color="red">mdi-alert</v-icon>
                  <span style="color: black"
                  >Consider using our filters to narrow down your search to get meaningful
                  information.</span
                  >
                </div>
                </v-tooltip>
              </div>
              </div>
              <div class="job-posting-pills md-scrollbar" v-if="filterItems.length > 0">
              <PillButton
                v-for="(item, index) in sortedFilterItems"
                :key="index"
                :border="item.run ? false : true"
                :borderColor="item.run ? '#929292' : ''"
                fontSize="12"
                :textColor="item.run ? '#444444' : '#5A5959'"
                :fontFamily="'Roboto'"
                :backgroundColor="item.run ? '#F0FFCF' : 'white'"
                :action="'removePill'"
                borderWidth="1px"
                :height="'32px'"
                :text="item.name"
                :width="'fit-content'"
                @removePill="removePill(item)"
              ></PillButton>
              <div class="clear-all-filters" @click="removeAllFilters">
                <span> <v-icon color="grey lighten-1">mdi-close</v-icon> Clear All Filters</span>
              </div>
              </div>
            </div>
          </div>
          <v-row class="mb-5">
            <v-card width="100%" min-height="100px" elevation="2">
              <JobPostingsBanner
                ref="jobPostingsBannerContainer"
                :wageTimeFrame="advertisedWageRangesButtonActive"
                :showCompaniesAndJobPostingsLoading="showBannerJobPostingsAndUniqueCompaniesLoading"
                :showMedianAdvertisedWageLoading="showBannerMedianAdvertisedWageLoading"
              />
            </v-card>
          </v-row>
          <v-row>
            <!-- First Row -->
            <v-row ref="rowContainer1" class="first-row-height">
              <!-- First Column -->
              <v-col cols="6" class="pr-2">
                <v-card v-if="!showAdvertisedSalaryDistributionLoading" elevation="2" class="advertised-salary-distribution fill-height">
                  <v-container>
                    <v-card-title class="gray-text"> Advertised Wage Distribution </v-card-title>
                    <v-card-subtitle
                    class="text-left font-italic gray-text"
                    v-if="advertisedSalaryDistribution.advertised_salary_data.length > 0"
                    >
                    {{ advertisedSalaryDistribution.percentage_advertised_salary_wage }}
                    </v-card-subtitle>
                    <v-card-text
                    class="text-left font-italic gray-text"
                    v-if="determineIfWeDispatchCompanyWageData()"
                    >
                    {{ determineAdvertisedHigherWageThanCompany }}
                    </v-card-text>
                    <PayDistribution
                    :distribution="this.advertisedSalaryDistribution.advertised_salary_data"
                    :labels="this.advertisedSalaryDistribution.labels"
                    :timeFrame="advertisedWageRangesButtonActive"
                    @selectedDistribution="handleSelectedDistribution"
                    />
                    <div class="extra-details">
                    <p class="fw-700">
                      <label for="wage-timeframe-selector">Wage Timeframe Selector</label>
                    </p>
                    <div class="wage-filters-competition-page">
                      <v-radio-group id="wage-timeframe-selector" v-model="advertisedWageRangesButtonActive" row>
                      <v-radio
                        v-for="button in advertisedWageRangesButtons"
                        :key="button.id"
                        :label="`${button.label}`"
                        :value="`${button.label === 'Annual' ? 'Yearly' : button.label}`"
                        @click="handleTimeFrameChange(button)"
                        style="justify-content: space-between"
                      ></v-radio>
                      </v-radio-group>
                    </div>
                    </div>
                    <div class="extra-details mb-2">
                    <p class="fw-700">Add your company</p>
                    <p>
                      Add your wage data for a quick look at how your company compares to the
                      competition.
                    </p>
                    <v-dialog>
                      <!-- eslint-disable-next-line vue/no-unused-vars -->
                      <template v-slot:activator="{ props }">
                      <v-btn @click="handleCompanyWagesVisibleChange">
                        <v-icon aria-label="Add your company's data" color="grey lighten-1"
                        >mdi-plus-box</v-icon
                        >
                        <span> {{ determineWageDataButtonText }}</span>
                      </v-btn>
                      </template>
                    </v-dialog>
                    <CompanyWagesModal
                      :companyWagesIsVisible="companyWagesIsVisible"
                      @close="handleClose"
                      @completed="dispatchCompanyWageData"
                    />
                    </div>
                  </v-container>
                </v-card>
                <v-card v-else elevation="2" class="fill-height">
                  <LoadingSpinner
                  class="loading-spinner"
                  :loading="showAdvertisedSalaryDistributionLoading"
                  :color="'#70A300'"
                  :size="'150px'"
                  />
                </v-card>
              </v-col>
              <!-- Second Column -->
              <v-col cols="6" class="pl-1">
                <v-card v-if="!showAdvertisedWageRangesLoading" elevation="2" class="fill-height">
                  <v-container>
                    <v-card-title class="gray-text"> Advertised Wage Ranges by Company </v-card-title>
                    <PayRate
                      class="pay-rate"
                      :topLabel="'companies_postings'"
                      :minimumLabel="determineWageRangeMinimumLabel"
                      :maximumLabel="determineWageRangeMaxmiumLabel"
                      :searchedTimeFrame="advertisedWageRangesButtonActive"
                      :payData="advertisedWageRanges"
                    />
                  </v-container>
                </v-card>
                <v-card v-else elevation="2" class="fill-height">
                  <LoadingSpinner
                  class="loading-spinner"
                  :loading="showAdvertisedWageRangesLoading"
                  :color="'#70A300'"
                  :size="'150px'"
                  />
                </v-card>
              </v-col>
            </v-row>
            <!-- Second Row -->
            <v-row class="second-row-height mt-0">
              <!-- First Column -->
              <v-col cols="6" class="pr-2">
                <v-card v-if="!postingsByCompanyLoading" elevation="2">
                  <v-container>
                    <v-card-title class="gray-text">Postings by Company</v-card-title>
                    <HorizontalBarChart
                      style="width: 100%"
                      :sort-by-options="postingsByCompanySortOptions"
                      id="postings-by-company-chart"
                      :scrollable="true"
                      :use-filters="true"
                      :use-pagination="true"
                      :use-filter-name="true"
                      filter-name="Company Names"
                      :bar-chart-data="postingsByCompany"
                      :data-present="postingsByCompanyPresence"
                      key-for-data="company_count"
                      key-for-labels="company_name_to_display"
                    />
                  </v-container>
                </v-card>
                <v-card v-else width="100%" elevation="2" class="fill-height">
                  <LoadingSpinner
                    class="loading-spinner"
                    :loading="postingsByCompanyLoading"
                    :color="'#70A300'"
                    :size="'150px'"
                  />
                </v-card>
                <v-card v-if="!jobPostingsMapLoading" elevation="2" class="mt-3">
                  <v-container>
                    <v-card-title class="gray-text">Postings by City
                      <!-- <v-switch :label="determineLabel" v-model="clusterMode"></v-switch> -->
                    </v-card-title>
                    <JobPostingsMap :cluster-mode="clusterMode"/>
                  </v-container>
                  <v-container>
                    <HorizontalBarChart
                      id="geographical-data-chart"
                      :bar-color="'#70A300'"
                      :bar-chart-data="geographicalChartData"
                      :use-filters="true"
                      :use-filter-name="true"
                      filter-name="City, State"
                      key-for-labels="location_name"
                      :sort-by-options="postingsByCitySortOptions"
                      key-for-data="number_of_postings"
                      :use-pagination="true"
                      key-for-footer="cities"
                      :data-present="geographicalChartData.length > 0"
                      :scrollable="true"
                    />
                  </v-container>
                </v-card>
                <v-card v-else width="100%" elevation="2" class="fill-height">
                  <LoadingSpinner
                    class="loading-spinner"
                    :loading="jobPostingsMapLoading"
                    :color="'#70A300'"
                    :size="'150px'"
                  />
                </v-card>
              </v-col>
              <!-- Second Column -->
              <v-col cols="6" class="pl-1 full-width">
                <v-row style="margin: 0; min-height: 30vh">
                  <v-col cols="12" style="padding: 0">
                    <v-card v-if="!hometimeBenefitDataLoading" elevation="2" class="fill-height">
                      <HorizontalBarChart
                        :bar-chart-data="hometimeBenefitData"
                        id="hometime-benefit-chart"
                        :title="'Number of Postings Listing Hometime as a Benefit'"
                        :data-present="hometimeBenefitDataPresence"
                        key-for-data="hometime_amount"
                        key-for-labels="hometime_timeframe"
                      >
                      <hometime-details-menu
                        :msaCodes="msaCodes"
                        :stateCodes="stateCodes"
                        :countyCodes="countyCodes"
                        :regionCodes="regionCodes"
                        :subRegionCodes="subRegionCodes"
                        :formattedStartDate="startingTimeFrame"
                        :formattedEndDate="endingTimeFrame"
                        :jobTypeFilters="jobTypeFilters"
                        :companySearched="companySearched"
                        :advertisedWageRangesButtonActive="advertisedWageRangesButtonActive"
                      ></hometime-details-menu>
                      </HorizontalBarChart>
                    </v-card>
                    <v-card v-else elevation="2" class="fill-height">
                      <LoadingSpinner
                      class="loading-spinner"
                      :loading="hometimeBenefitDataLoading"
                      :color="'#70A300'"
                      :size="'150px'"
                      />
                    </v-card>
                  </v-col>
                </v-row>
                <v-row class="pt-1" style="margin: 0; min-height: 70vh">
                  <v-col cols="12" style="padding: 0">
                    <v-card v-if="!bonusDataLoading" elevation="2" class="fill-height">
                      <BonusSection />
                    </v-card>
                    <v-card v-else elevation="2" class="fill-height">
                      <LoadingSpinner
                      class="loading-spinner"
                      :loading="bonusDataLoading"
                      :color="'#70A300'"
                      :size="'150px'"
                      />
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-row>
        </div>
      </v-col>
    </div>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import { debounce } from 'lodash/function';
import CountyMapModal from '../StyledComponents/Modals/CountyMapModal.vue';
import PillButton from '../StyledComponents/PillButton.vue';
import JobPostingsBanner from './JobPostingsBanner.vue';
import PayRate from '../Charts/PayAndBenefits/PayRate.vue';
import LoadingSpinner from '../StyledComponents/LoadingSpinner.vue';
import PayDistribution from '../Charts/PayAndBenefits/PayDistribution.vue';
import LocationListings from '../StyledComponents/LocationListings.vue';
import Accordion from '../Market/Accordion.vue';
import CompanyWagesModal from '../StyledComponents/Modals/CompanyWagesModal.vue';
import BonusSection from './BonusSection.vue';
import JobPostingsMap from './JobPostingsMap.vue';
import HorizontalBarChart from '../Charts/Competition/HorizontalBarChart.vue';
import PostingDateCalendar from './PostingDateCalendar.vue';
import HometimeDetailsMenu from '../Menus/HometimeDetailsMenu.vue';

export default {
  name: 'JobPostings',
  components: {
    JobPostingsMap,
    HometimeDetailsMenu,
    PostingDateCalendar,
    CountyMapModal,
    PillButton,
    JobPostingsBanner,
    PayRate,
    LoadingSpinner,
    PayDistribution,
    LocationListings,
    Accordion,
    CompanyWagesModal,
    HorizontalBarChart,
    BonusSection,
  },
  data() {
    return {
      date: [],
      searchedPage: 0,
      menu: false,
      isCollapsed: false,
      warningAcknowledged: false,
      postingDateChosen: null,
      typedLocationName: '',
      postingDateOptions: [
        {
          months: 1,
          label: 'Prior 30 days',
          checked: false,
        },
        {
          days: 'previous_month',
          label: 'Last Month',
          checked: false,
        },
        {
          months: 3,
          label: 'Past 3 Months',
          checked: false,
        },
        {
          months: 6,
          label: 'Past 6 Months',
          checked: false,
        },
        {
          months: 12,
          label: 'Past 12 Months',
          checked: false,
        },
      ],
      postingsByCompanySortOptions: [
        {
          title: 'Sort by Max', key: 0, icon: 'mdi-sort-numeric-descending', columnName: 'company_count', ascending: false, integer: true,
        },
        {
          title: 'Sort by Min', key: 1, icon: 'mdi-sort-numeric-ascending', columnName: 'company_count', ascending: true, integer: true,
        },
        {
          title: `Sort by Company Name`, key: 2, icon: 'mdi-sort-alphabetical-ascending', columnName: 'company_name_to_display', ascending: true, integer: false,
        },
      ],
      postingsByCitySortOptions: [
        {
          title: 'Sort by Max', key: 0, icon: 'mdi-sort-numeric-descending', columnName: 'number_of_postings', ascending: false, integer: true,
        },
        {
          title: 'Sort by Min', key: 1, icon: 'mdi-sort-numeric-ascending', columnName: 'number_of_postings', ascending: true, integer: true,
        },
        {
          title: `Sort by City Name`, key: 2, icon: 'mdi-sort-alphabetical-ascending', columnName: 'location_name', ascending: true, integer: false,
        },
      ],
      clusterMode: true,
      accordionFilterOptions: [
        {
          accordionTitle: 'Solo or Team Position',
          radioButtonOptions: [
            {
              checked: false,
              name: 'Solo',
              dbColumnName: 'solo_driver',
              filterType: 'soloTeamFilters',
            },
            {
              checked: false,
              name: 'Team',
              dbColumnName: 'team_driver',
              filterType: 'soloTeamFilters',
            },
            // {
            // eslint-disable-next-line max-len
            //   checked: 'teamEstablished', name: 'Team - Established', dbColumnName: 'team_type_established', filterType: 'soloTeamFilter',
            // },
            // {
            // eslint-disable-next-line max-len
            //   checked: 'teamWillingToMatch', name: 'Team - Willing to Match', dbColumnName: 'team_type_willing_to_match', filterType: 'soloTeamFilter',
            // },
          ],
          radioFilterType: 'soloTeamFilters',
        },
        {
          accordionTitle: 'Posting Route Type',
          radioButtonOptions: [
            {
              checked: false,
              name: 'Local',
              dbColumnName: 'local_route_type',
              filterType: 'routeTypeFilters',
            },
            {
              checked: false,
              name: 'Regional',
              dbColumnName: 'regional_route_type',
              filterType: 'routeTypeFilters',
            },
            {
              checked: false,
              name: 'Over the Road',
              dbColumnName: 'otr_route_type',
              filterType: 'routeTypeFilters',
            },
            {
              checked: false,
              name: 'Dedicated',
              dbColumnName: 'dedicated_route_type',
              filterType: 'routeTypeFilters',
            },
          ],
          radioFilterType: 'routeTypeFilters',
        },
        {
          accordionTitle: 'Posting Haul Type',
          radioButtonOptions: [
            {
              checked: false,
              name: 'Dry Van',
              dbColumnName: 'dry_van_haul_type',
              filterType: 'haulTypeFilters',
            },
            {
              checked: false,
              name: 'Flatbed',
              dbColumnName: 'flatbed_haul_type',
              filterType: 'haulTypeFilters',
            },
            {
              checked: false,
              name: 'Refrigerated',
              dbColumnName: 'refrigerated_haul_type',
              filterType: 'haulTypeFilters',
            },
            {
              checked: false,
              name: 'Hazmat',
              dbColumnName: 'hazmat_haul_type',
              filterType: 'haulTypeFilters',
            },
            {
              checked: false,
              name: 'Tanker',
              dbColumnName: 'tanker_haul_type',
              filterType: 'haulTypeFilters',
            },
            {
              checked: false,
              name: 'LTL',
              dbColumnName: 'ltl_haul_type',
              filterType: 'haulTypeFilters',
            },
            {
              checked: false,
              name: 'Auto Hauler',
              dbColumnName: 'auto_hauler_haul_type',
              filterType: 'haulTypeFilters',
            },
            {
              checked: false,
              name: 'Doubles / Triples',
              dbColumnName: 'doubles_triples_haul_type',
              filterType: 'haulTypeFilters',
            },
            {
              checked: false,
              name: 'Heavy Haul / Specialized',
              dbColumnName: 'heavy_haul_specialized_haul_type',
              filterType: 'haulTypeFilters',
            },
            {
              checked: false,
              name: 'Power Only',
              dbColumnName: 'power_only_haul_type',
              filterType: 'haulTypeFilters',
            },
          ],
          radioFilterType: 'haulTypeFilters',
        },
      ],
      convertAdvertisedWageRange: true,
      advertisedWageRangesButtons: [
        { label: 'Annual', id: 0 },
        { label: 'Weekly', id: 1 },
        { label: 'Hourly', id: 2 },
        { label: 'CPM', id: 3 },
      ],
      advertisedWageRangesButtonActive: 'Yearly',
      isVisible: false,
      companyWagesIsVisible: false,
      driverTypeOptions: [
        { checked: false, name: 'Company Driver', dbColumnName: 'company_driver_inferred' },
        { checked: false, name: 'Owner-Operator', dbColumnName: 'owner_operator' },
        { checked: false, name: 'Lease Purchase', dbColumnName: 'lease_purchase' },
        { checked: false, name: 'Student Driver', dbColumnName: 'student_driver' },
        { checked: false, name: 'Yard Jockey', dbColumnName: 'yard_jockey' },
      ],
      animated: {
        sideBar: false,
        topBar: false,
      },
      companySearched: [],
      countyCodes: [],
      msaCodes: [],
      stateCodes: [],
      regionCodes: [],
      subRegionCodes: [],
      includeActivePostings: false,
      startingTimeFrame: null,
      endingTimeFrame: null,
      selectedTimeFrame: null,
      inputtedSalary: null,
      inputtedCompanyName: null,
      jobTypeFilters: {
        driverTypeFilters: [],
        soloTeamFilters: [],
        routeTypeFilters: [],
        haulTypeFilters: [],
      },
      defaultSelectedAreas: new Map(),
      filterItems: [],
      needsUpdate: false,
      initialLoad: true,
      autocompleteLoading: false,
    };
  },
  watch: {
    includeActivePostings() {
      if (!this.includeActivePostings) {
        this.startingTimeFrame = null;
        this.endingTimeFrame = null;
      }
    },
    companySearched(newSelectedArray, oldSelectedArray) {
      if (newSelectedArray.length - oldSelectedArray.length === 1) {
        this.addCompanySearchedToFilterChips(newSelectedArray[newSelectedArray.length - 1]);
        this.highlightSearchText();
      } else {
        this.removeCompanySearchedFromFilterChips(
          oldSelectedArray.filter((a) => !newSelectedArray.includes(a))[0],
        );
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.isCollapsed = true;
    }, 2000);

    const pageValue = {
      link: {
        title: this.competitionPageSelected,
        linkId: `Job Postings ${this.competitionPageSelected}`,
        section: this.competitionPageSelected,
      },
    };
    const data = JSON.stringify({
      title: this.competitionPageSelected,
      linkId: `Job Postings ${this.competitionPageSelected}`,
      section: this.competitionPageSelected,
    });
    sessionStorage.setItem('lastPageViewed', data);
    this.$store.dispatch('global/addPageLinkHighlightAndNavLinkBorder', pageValue);
    const competitionData = JSON.parse(sessionStorage.getItem('competitionFilters'));
    if (competitionData) {
      // Cache filters
      this.filterItems = competitionData;
      this.filterItems.forEach((item) => {
        if (item.type === 'timeFrame') {
          if (item.name.startsWith('From')) {
            const arr = item.name.split(' ');
            const [startDate, endDate] = [new Date(arr[1]), new Date(arr[3])];
            this.postingDateChosen = null;
            this.startingTimeFrame = startDate;
            this.endingTimeFrame = endDate;
            this.selectedTimeFrame = { start: startDate, end: endDate };
          } else {
            // Setting Timeframe filters
            this.postingDateOptions.find((pdo) => pdo.label === item.name).checked = true;
            this.postingDateChosen = this.postingDateOptions.find((pdo) => pdo.label === item.name);
            if (typeof this.postingDateChosen.months === 'number') {
              const today = new Date();
              // eslint-disable-next-line max-len
              this.startingTimeFrame = new Date(today.setMonth(today.getMonth() - this.postingDateChosen.months));
              this.endingTimeFrame = new Date();
            } else {
              // eslint-disable-next-line max-len
              this.startingTimeFrame = new Date(new Date(new Date().setDate(1)).setMonth(new Date().getMonth() - 1));
              this.endingTimeFrame = new Date(new Date().setDate(0));
            }
          }
        } else if (item.type === 'driverTypeFilters') {
          // Setting Job Type filters
          this.jobTypeFilters[item.type].push(item.field);
          this.driverTypeOptions.find((dto) => dto.name === item.name).checked = true;
        } else if (item.type === 'soloTeamFilters' || item.type === 'routeTypeFilters' || item.type === 'haulTypeFilters') {
          this.jobTypeFilters[item.type].push(item.field);
          this.accordionFilterOptions.find((afo) => afo.radioFilterType === item.type)
            .radioButtonOptions.find((rbo) => rbo.name === item.name).checked = true;
        } else if (item.type === 'locationSearch') {
          this.$data[`${item.locationType}Codes`].push(item.fips);
        } else if (item.type === 'radiusFilter') {
          this.countyCodes = this.countyCodes.concat(item.fips.map((c) => c.fips));
          const countyMapModalData = JSON.parse(sessionStorage.getItem('countyMapModalData'));
          if (countyMapModalData) {
            this.defaultSelectedAreas = new Map(countyMapModalData);
          }
        } else if (item.type === 'searchedCompany') {
          this.companySearched.push(item.name);
        }
      });
      this.runSearch(false);
    } else {
      this.needsUpdate = true;
      const today = new Date();
      this.postingDateChosen = this.postingDateOptions.find((pdo) => pdo.label === 'Past 3 Months');
      this.startingTimeFrame = new Date(today.setMonth(today.getMonth() - 3));
      this.endingTimeFrame = new Date();
      this.filterItems.push({
        name: 'Past 3 Months',
        run: false,
        type: 'timeFrame',
      });
      this.$store.dispatch('jobPostings/fetchThreeMonthData');
    }
    this.$store.commit('jobPostings/setAdvertisedWageRangesLoading', true);
    this.$store.commit('jobPostings/setAdvertisedSalaryDistributionLoading', true);
    this.$store.commit('jobPostings/setPostingsByCompanyLoading', true);
    this.$store.commit('jobPostings/setBonusDataLoading', true);
    this.$store.commit('jobPostings/setHometimeBenefitDataLoading', true);
  },
  computed: {
    colSize() {
      return this.isCollapsed ? 9 : 12;
    },
    determineLabel() {
      return 'Cluster';
    },
    selectAll() {
      // eslint-disable-next-line arrow-parens
      const checker = (arr, target) => target.every(v => arr.includes(v));
      if (this.companies.length > 0) {
        return checker(this.companySearched, this.companies);
      }
      // eslint-disable-next-line max-len
      return checker(this.companySearched, this.postingsByCompany.slice(0, this.$refs['company-search-select']?.lastItem).map((company) => company.company_name_to_display));
    },
    selectIcon() {
      if (this.selectAll) return 'check_box';
      return 'check_box_outline_blank';
    },
    determineAdvertisedHigherWageThanCompany() {
      if (this.determineIfWeDispatchCompanyWageData()) {
        // eslint-disable-next-line max-len
        const percentage = (
          (this.postingsWithHigherWage.postings_with_high_wage
            / Number.parseFloat(this.postingsWithHigherWage.wage_sum))
            * 100
        ).toFixed(2);
        // eslint-disable-next-line max-len
        return `There are ${this.postingsWithHigherWage.postings_with_high_wage?.toLocaleString()} postings (${percentage}%) that advertise a higher wage.`;
      }
      return '';
    },
    determineCompanies() {
      let result;
      if (this.companies.length > 0) {
        result = this.companies.map((company) => {
          if (company.length > 20) {
            return { title: `${company}`, value: company };
          }
          return { title: company, value: company };
        });
      } else if (this.companies.length === 0 && this.typedLocationName.trim() === '') {
        // eslint-disable-next-line max-len
        result = this.postingsByCompany.map((company) => company.company_name_to_display).map((company) => {
          if (company.length > 20) {
            return { title: `${company}`, value: company };
          }
          return { title: company, value: company };
        });
      } else {
        result = this.companies;
      }
      return result;
    },
    determineSearchButtonText() {
      if ((this.needsUpdate || this.filterItems.some((i) => !i.run)) && !this.initialLoad) {
        return 'Update Search';
      }
      return 'Search';
    },
    determineWageDataButtonText() {
      if (this.checkIfWageRangeEntered) {
        return 'Update Data';
      }
      return 'Add Wage Data';
    },
    determineWageRangeMinimumLabel() {
      // this is used for the PayRate component to be more dynamic
      switch (this.advertisedWageRangesButtonActive) {
        case 'Yearly':
          return 'yearly_low';
        case 'Weekly':
          return 'weekly_low';
        case 'Hourly':
          return 'hourly_low';
        case 'CPM':
          return 'cpm_low';
        default:
          return '';
      }
    },
    determineWageRangeMaxmiumLabel() {
      // this is used for the PayRate component to be more dynamic
      switch (this.advertisedWageRangesButtonActive) {
        case 'Yearly':
          return 'yearly_high';
        case 'Weekly':
          return 'weekly_high';
        case 'Hourly':
          return 'hourly_high';
        case 'CPM':
          return 'cpm_high';
        default:
          return '';
      }
    },
    checkIfWageRangeEntered() {
      // eslint-disable-next-line
      return [
        this.yearlyWageRange.completed,
        this.weeklyWageRange.completed,
        this.hourlyWageRange.completed,
        this.cpmWageRange.completed,
      ].includes(true);
    },
    ...mapGetters('jobPostings', {
      showAdvertisedWageRangesLoading: 'getAdvertisedWageRangesLoading',
      showAdvertisedSalaryDistributionLoading: 'getAdvertisedSalaryDistributionLoading',
      showBannerJobPostingsAndUniqueCompaniesLoading: 'getBannerJobPostingsAndUniqueCompaniesLoading',
      showBannerMedianAdvertisedWageLoading: 'getBannerMedianAdvertisedWageLoading',
      postingsWithHigherWage: 'getInputtedCompanyDistributionStatistics',
      advertisedWageRanges: 'getAdvertisedWageRanges',
      advertisedSalaryDistribution: 'getAdvertisedSalaryDistribution',
      hasMoreCompanies: 'getHasMoreCompanies',
      companyName: 'getCompanyName',
      companies: 'getCompanies',
      payData: 'getAdvertisedPayData',
      yearlyWageRange: 'getInputtedYearlyWageRange',
      weeklyWageRange: 'getInputtedWeeklyWageRange',
      hourlyWageRange: 'getInputtedHourlyWageRange',
      cpmWageRange: 'getInputtedCPMWageRange',
      postingsByCompany: 'getPostingsByCompany',
      postingsByCompanyPresence: 'getPostingsByCompanyPresence',
      postingsByCompanyLoading: 'getPostingsByCompanyLoading',
      bonusDataLoading: 'getBonusDataLoading',
      hometimeBenefitData: 'getHometimeBenefitData',
      hometimeBenefitDataLoading: 'getHometimeBenefitDataLoading',
      hometimeBenefitDataPresence: 'getHometimeBenefitDataPresence',
      inputtedCompanyDistributionStatistics: 'getInputtedCompanyDistributionStatisticsLoading',
      advertisedSalaryDistributionPresence: 'getAdvertisedSalaryDistributionPresence',
      jobPostingsMapLoading: 'getJobPostingsMapLoading',
      geographicalChartData: 'getGeographicalChartData',
    }),
    ...mapGetters({
      competitionPageSelected: `global/getDriverOrMechanicData`,
    }),
    sortedFilterItems() {
      const debounceLeft = debounce(this.scrollToEndOfPillSection, 90);
      debounceLeft();
      // we are sorting the filter chip items here before passing it into the PillButton.vue
      const sortingArray = [
        'radiusFilters',
        'dateFilters',
        'driverTypeFilters',
        'soloTeamFilters',
        'routeTypeFilters',
        'haulTypeFilters',
      ];
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties, max-len
      return this.filterItems.sort(
        (a, b) => sortingArray.indexOf(a.type) - sortingArray.indexOf(b.type),
      );
    },
  },
  methods: {
    handleDropdownToggle() {
      this.highlightSearchText();
    },
    highlightSearchText() {
      if (this.$refs.search_carriers?.$refs.input) {
        // Select the text inside the input
        this.$refs.search_carriers.$refs.input.select();
      }
    },
    handleSelectAll() {
      if (!this.selectAll) {
        if (this.companies.length > 0) {
          this.companies.forEach((company) => {
            if (!this.companySearched.includes(company)) {
              this.companySearched.push(company);
              this.addCompanySearchedToFilterChips(company);
            }
          });
        } else {
          this.postingsByCompany.slice(0, this.$refs['company-search-select']?.lastItem).map((comp) => comp.company_name_to_display).forEach((company) => {
            this.companySearched.push(company);
            this.addCompanySearchedToFilterChips(company);
          });
        }
      } else {
        // eslint-disable-next-line no-restricted-syntax
        for (const [idx, company] of this.determineCompanies.entries()) {
          if (idx >= this.$refs['company-search-select']?.lastItem) {
            break;
          }
          const index = this.companySearched.indexOf(company.value);
          if (index !== -1) {
            this.companySearched.splice(index, 1);
            this.removeCompanySearchedFromFilterChips(company.value);
          }
        }
      }
    },
    handleAreaRemoved(areaId) {
      const removedArea = this.defaultSelectedAreas.get(areaId);
      if (removedArea) {
        const removedAreaFips = removedArea.counties.map((ra) => ra.fips);
        // eslint-disable-next-line max-len
        this.filterItems = this.filterItems.filter((item) => item.display_name !== removedArea.display_name);
        this.countyCodes = this.countyCodes.filter((cc) => !removedAreaFips.includes(cc));
        this.defaultSelectedAreas.delete(areaId);
      }
    },
    toggleFilter() {
      this.isCollapsed = !this.isCollapsed;
    },
    debounceFunction: debounce(function () {
      this.autocompleteLoading = true;
      this.$store.dispatch('jobPostings/fetchCompanies', { query: this.typedLocationName, page: this.searchedPage }).then(() => {
        this.autocompleteLoading = false;
      });
    }, 1000),
    async handleSelectedDistribution(data) {
      const formattedPayload = {
        ...this.formatPayload(),
        ...data,
      };
      this.$store.commit('jobPostings/setSelectedDistributionWageRangesLoading', true);
      await this.$store.dispatch(
        'jobPostings/fetchSelectedDistributionWageRages',
        formattedPayload,
      );
    },
    scrollToEndOfPillSection() {
      const jobPostingPillsElement = document.getElementsByClassName('job-posting-pills')[0];
      if (jobPostingPillsElement !== undefined) {
        jobPostingPillsElement.scrollTo({
          left: jobPostingPillsElement.scrollWidth + 500,
          behavior: 'smooth',
        });
      }
    },
    changePostingDateFilter(data) {
      const today = new Date();
      const { label, months } = data;
      // eslint-disable-next-line  no-return-assign, no-param-reassign
      this.postingDateOptions.forEach((pd) => (pd.checked = false));
      this.postingDateOptions.find((pd) => pd.months === months).checked = true;
      if (typeof months === 'number') {
        this.startingTimeFrame = new Date(today.setMonth(today.getMonth() - months));
        this.endingTimeFrame = new Date();
      } else {
        const start = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        this.startingTimeFrame = start;
        const end = new Date(today.getFullYear(), today.getMonth(), 0);
        this.endingTimeFrame = end;
      }
      this.addTimeFrameFilterChip({
        name: label,
        type: 'timeFrame',
        run: false,
      });
    },
    addTimeFrameFilterChip(timeFrame) {
      this.needsUpdate = true;
      this.filterItems = this.filterItems.filter((i) => i.type !== 'timeFrame');
      this.filterItems.push(timeFrame);
    },
    addCompanySearchedToFilterChips(company) {
      this.needsUpdate = true;
      this.filterItems.push({
        name: company,
        value: company,
        type: 'searchedCompany',
        run: false,
      });
    },
    removeCompanySearchedFromFilterChips(company) {
      this.needsUpdate = true;
      this.filterItems = this.filterItems.filter((item) => !(item.name === company && item.type === 'searchedCompany'));
    },
    removeAllFilters() {
      this.needsUpdate = true;
      this.postingDateChosen = null;
      this.$refs.postingDateCalendar.clearCalendarRange();
      this.locationSearchLocations = [];
      this.radiusToolLocations = [];
      this.jobTypeFilters = {
        driverTypeFilters: [],
        soloTeamFilters: [],
        routeTypeFilters: [],
        haulTypeFilters: [],
      };
      // eslint-disable-next-line max-len, no-param-reassign, no-return-assign
      this.accordionFilterOptions.forEach((fo) => fo.radioButtonOptions.forEach((radioButton) => (radioButton.checked = false)));
      // eslint-disable-next-line no-return-assign, no-param-reassign
      this.driverTypeOptions.forEach((driverTypeOption) => (driverTypeOption.checked = false));
      this.countyCodes = [];
      this.stateCodes = [];
      this.msaCodes = [];
      this.regionCodes = [];
      this.subRegionCodes = [];
      this.defaultSelectedAreas = new Map();
      // this.$refs['auto-complete'].internalValue = [];
      this.filterItems = [];
      this.companySearched = [];
      this.searchedLocations = [];
      this.startingTimeFrame = null;
      this.endingTimeFrame = null;
    },
    handleTimeFrameChange(button) {
      const buttonLabel = button.label === 'Annual' ? 'Yearly' : button.label;

      this.advertisedWageRangesButtonActive = buttonLabel;
      // Pass false to runSearch method to skip fetching
      // job postings count and unique companies count
      this.runSearch(true);
    },
    setCalendarDateRange(value) {
      this.needsUpdate = true;
      this.postingDateChosen = null;

      const range = value;
      const [start, end] = range;

      this.startingTimeFrame = start;
      this.endingTimeFrame = end;
      this.selectedTimeFrame = { start: value[0], end: value[1] };
      this.addTimeFrameFilterChip({
        name: `From ${new Date(value[0]).toLocaleDateString('en-US', { timeZone: 'UTC' })} to ${new Date(
          value[1],
        ).toLocaleDateString('en-US', { timeZone: 'UTC' })}`,
        type: 'timeFrame',
        run: false,
      });
    },
    handleVisibleChange() {
      this.isVisible = true;
    },
    handleCompanyWagesVisibleChange() {
      this.companyWagesIsVisible = !this.companyWagesIsVisible;
    },
    handleRadiusSelection(emittedObject) {
      this.needsUpdate = true;
      const { countyNames, originalMapObject } = emittedObject;
      this.defaultSelectedAreas = originalMapObject;
      this.filterItems = this.filterItems.filter((fi) => fi.type !== 'radiusFilter');
      countyNames.forEach((countyName) => {
        // eslint-disable-next-line no-param-reassign
        countyName.run = false;
        this.filterItems.push(countyName);
        this.countyCodes = this.countyCodes.concat(countyName.fips.map((c) => c.fips));
      });
      this.countyCodes = [...new Set(this.countyCodes)];
      this.handleClose();
    },
    removePill(pill) {
      this.needsUpdate = true;
      const radioFilters = [
        'driverTypeFilters',
        'soloTeamFilters',
        'routeTypeFilters',
        'haulTypeFilters',
      ];
      if (pill.type === 'radiusFilter') {
        this.filterItems = this.filterItems.filter((item) => item.name !== pill.name);
        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of this.defaultSelectedAreas) {
          if (value.display_name === pill.display_name) {
            this.defaultSelectedAreas.delete(key);
          }
        }
        // eslint-disable-next-line max-len
        this.countyCodes = this.countyCodes.filter((county) => !pill.fips.map((f) => f.fips).includes(county));
      } else if (radioFilters.includes(pill.type)) {
        this.filterItems = this.filterItems.filter((item) => item.name !== pill.name);
        // since we pick up from jobTypeFilters
        if (pill.type !== 'driverTypeFilters') {
          // eslint-disable-next-line max-len
          this.accordionFilterOptions
            .find((filter) => filter.radioFilterType === pill.type)
            .radioButtonOptions.find((rb) => rb.name === pill.name).checked = false;
        } else {
          this.driverTypeOptions.find((d) => d.name === pill.name).checked = false;
        }
        // eslint-disable-next-line max-len
        this.jobTypeFilters[pill.type] = this.jobTypeFilters[pill.type].filter(
          (f) => f !== pill.field,
        );
      } else if (pill.type === 'searchedCompany') {
        // eslint-disable-next-line max-len
        this.removeCompanySearchedFromFilterChips(pill.name);
        this.companySearched = this.companySearched.filter((comp) => comp !== pill.name);
      } else if (pill.type === 'timeFrame') {
        this.$refs.postingDateCalendar.clearCalendarRange();
        this.postingDateChosen = null;
        this.startingTimeFrame = null;
        this.endingTimeFrame = null;
        this.filterItems = this.filterItems.filter((item) => item.name !== pill.name);
      } else if (pill.type === 'locationSearch') {
        if (pill.locationType === 'msa') {
          this.msaCodes = this.msaCodes.filter((msa) => msa !== pill.fips);
        } else if (pill.locationType === 'state') {
          this.stateCodes = this.stateCodes.filter((msa) => msa !== pill.fips);
        } else if (pill.locationType === 'county') {
          this.countyCodes = this.countyCodes.filter((msa) => msa !== pill.fips);
        } else if (pill.locationType === 'region') {
          this.regionCodes = this.regionCodes.filter((msa) => msa !== pill.fips);
        } else if (pill.locationType === 'subregion') {
          this.subRegionCodes = this.subRegionCodes.filter((msa) => msa !== pill.fips);
        }
        this.filterItems = this.filterItems.filter((item) => !(item.type === 'locationSearch' && item.fips === pill.fips && item.locationType === pill.locationType));
      }
    },
    handleClose() {
      this.isVisible = false;
      this.companyWagesIsVisible = false;
    },
    handleLocationSelected(location) {
      const alreadyPushed = this.filterItems.some(
        (obj) => obj.fips === location.location_code_geoid,
      );
      if (!alreadyPushed) {
        this.needsUpdate = true;
        this.filterItems.push({
          name: location.location_name,
          fips: location.location_code_geoid,
          type: 'locationSearch',
          locationType: location.location_type,
        });
        if (location.location_type === 'msa') {
          this.msaCodes.push(location.location_code_geoid);
        } else if (location.location_type === 'state') {
          this.stateCodes.push(location.location_code_geoid);
        } else if (location.location_type === 'county') {
          this.countyCodes.push(location.location_code_geoid);
        } else if (location.location_type === 'region') {
          this.regionCodes.push(location.location_code_geoid);
        } else if (location.location_type === 'subregion') {
          this.subRegionCodes.push(location.location_code_geoid);
        }
      }
    },
    formatPayload() {
      const formattedStartDate = this.startingTimeFrame
        ? new Date(this.startingTimeFrame).toISOString().slice(0, 10).replaceAll('-', '/')
        : null;
      const formattedEndDate = this.endingTimeFrame
        ? new Date(this.endingTimeFrame).toISOString().slice(0, 10).replaceAll('-', '/')
        : null;
      const obj = {};
      const filterArray = [
        'radiusFilters',
        'driverTypeFilters',
        'soloTeamFilters',
        'routeTypeFilters',
        'haulTypeFilters',
      ];
      this.filterItems
        .filter((item) => filterArray.includes(item.type))
        .forEach((item) => {
          if (obj[item.type]) {
            obj[item.type].push(item.name);
          } else {
            obj[item.type] = [];
            obj[item.type].push(item.name);
          }
        });
      return {
        msa_codes: this.msaCodes,
        state_codes: this.stateCodes,
        county_codes: this.countyCodes,
        region_codes: this.regionCodes,
        sub_region_codes: this.subRegionCodes,
        posting_date_from: formattedStartDate,
        posting_date_to: formattedEndDate,
        job_type_filters: this.jobTypeFilters,
        company_names: this.companySearched,
        high_low_type: this.advertisedWageRangesButtonActive,
      };
    },
    changeJobType(val, field, filterType, filterName) {
      this.needsUpdate = true;
      if (val) {
        this.jobTypeFilters[filterType].push(field);
        this.filterItems.push({ name: filterName, type: filterType, field });
        if (filterType === 'driverTypeFilters') {
          this.driverTypeOptions.find((dto) => dto.name === filterName).checked = true;
        } else {
          this.accordionFilterOptions
            .find((afo) => afo.radioFilterType === filterType)
            .radioButtonOptions.find((rbo) => rbo.name === filterName).checked = true;
        }
      } else {
        // eslint-disable-next-line max-len
        this.jobTypeFilters[filterType] = this.jobTypeFilters[filterType].filter(
          (jtf) => jtf !== field,
        );
        this.filterItems = this.filterItems.filter((fi) => fi.name !== filterName);
        if (filterType === 'driverTypeFilters') {
          this.driverTypeOptions.find((dto) => dto.name === filterName).checked = false;
        } else {
          this.accordionFilterOptions
            .find((afo) => afo.radioFilterType === filterType)
            .radioButtonOptions.find((rbo) => rbo.name === filterName).checked = false;
        }
      }
    },
    noFiltersAdded() {
      // eslint-disable-next-line max-len
      return (
        this.filterItems.length === 0
        && !this.postingDateChosen
        && !this.selectedTimeFrame
        && this.companySearched.length === 0
      );
    },
    // eslint-disable-next-line default-param-last
    async runSearch(advertisedTimeFrameChanged = true, buttonClicked) {
      // Set minimum height before searching
      this.isCollapsed = false;

      if (this.advertisedSalaryDistributionPresence) {
        this.$refs.rowContainer1.style.minHeight = `${this.$refs.rowContainer1.offsetHeight}px`;
      } else {
        // When no data to display the height of the containers are large and hence reducing it
        this.$refs.rowContainer1.style.minHeight = '70vh';
      }
      this.$refs.jobPostingsBannerContainer.setWidth();

      // Run always except when advertisedData is requested
      if (this.noFiltersAdded() && !this.warningAcknowledged) {
        this.animated[buttonClicked] = true;
        setTimeout(() => {
          this.animated[buttonClicked] = false;
          this.warningAcknowledged = true;
        }, 3000);
        return;
      }
      this.needsUpdate = false;
      this.initialLoad = false;
      const filterString = JSON.stringify(this.filterItems);
      sessionStorage.setItem('competitionFilters', filterString);
      const defaultSelectedAreaString = JSON.stringify([...this.defaultSelectedAreas]);
      sessionStorage.setItem('countyMapModalData', defaultSelectedAreaString);
      this.filterItems = this.filterItems.map((fi) => ({
        ...fi,
        run: true,
      }));

      const formattedPayload = this.formatPayload();
      // Run always
      this.$store.commit('jobPostings/fetchBannerMedianAdvertisedTimeframeWageLoading', true);
      this.$store.commit('jobPostings/setAdvertisedWageRangesLoading', true);
      this.$store.commit('jobPostings/setAdvertisedSalaryDistributionLoading', true);

      this.$store.dispatch(
        'jobPostings/fetchBannerMedianAdvertisedTimeframeWage',
        formattedPayload,
      );
      this.$store.dispatch('jobPostings/searchAdvertisedSalaryDistribution', formattedPayload);
      this.$store.dispatch('jobPostings/searchAdvertisedWageRanges', formattedPayload);

      // Don't run when advertisedTimeFrameChanged
      if (!advertisedTimeFrameChanged) {
        this.$store.commit('jobPostings/setPostingsByCompanyLoading', true);
        this.$store.commit('jobPostings/setBonusDataLoading', true);
        this.$store.commit('jobPostings/setHometimeBenefitDataLoading', true);
        this.$store.commit('jobPostings/setJobPostingsMapLoading', true);
        this.dispatchCompanyWageData();
        this.$store.commit(
          'jobPostings/fetchBannerJobPostingsAndDistinctCompaniesDataLoading',
          true,
        );
        this.$store.dispatch(
          'jobPostings/fetchBannerJobPostingsAndDistinctCompaniesData',
          formattedPayload,
        );
        this.$store.dispatch('jobPostings/fetchPostingsByCompany', formattedPayload);
        this.$store.dispatch('jobPostings/fetchBonusInformation', formattedPayload);
        this.$store.dispatch('jobPostings/fetchHometimeBenefitData', formattedPayload);
        this.$store.dispatch('jobPostings/fetchGeographicalData', formattedPayload);
      }
    },
    determineIfWeDispatchCompanyWageData() {
      if (this.advertisedWageRangesButtonActive === 'CPM') {
        return this.cpmWageRange.completed;
      }
      return this.$store.getters[
        `jobPostings/getInputted${this.advertisedWageRangesButtonActive}WageRange`
      ].completed;
    },
    determineInputtedMid() {
      if (this.advertisedWageRangesButtonActive === 'CPM') {
        // eslint-disable-next-line max-len
        return (
          (Number.parseFloat(this.cpmWageRange.max, 10)
            + Number.parseFloat(this.cpmWageRange.min, 10))
          / 2
        );
      }
      // eslint-disable-next-line max-len
      const foundTimeFrame = this.$store.getters[
        `jobPostings/getInputted${this.advertisedWageRangesButtonActive}WageRange`
      ];
      // eslint-disable-next-line max-len
      return (
        (Number.parseFloat(foundTimeFrame.max, 10) + Number.parseFloat(foundTimeFrame.min, 10)) / 2
      );
    },
    async dispatchCompanyWageData() {
      if (this.determineIfWeDispatchCompanyWageData()) {
        // eslint-disable-next-line max-len
        // don't run this unless we have corresponding data for the selected timeFrame = 'Yearly'... etc
        const formattedPayload = this.formatPayload();
        formattedPayload.inputted_mid = this.determineInputtedMid();
        await this.$store.dispatch(
          'jobPostings/fetchInputtedCompanyDistributionStatistics',
          formattedPayload,
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>

.static-search {
  position: sticky;
  top: 0;
  background: white;
  z-index: 5;
}
  .collapsed-sidebar-btn {
    left: 16px;
    background-color: #F9F9F9;
    position: fixed;
    top: 118px;
  }
  .expanded-sidebar-btn {
    border-radius: 50%;
    min-width: 36px !important;
    width: 36px !important;
    height: 36px !important;
    left: 15%;
    background-color: #E0E0E0 !important;
    position: absolute;
    top: 20px;
  }
.start {
  border: 1px solid #000;
}
.col,
.toggle-filter-btn,
.filters-span {
  transition: .25s ease all;
}
.filters-span {
  text-transform: capitalize;
}
.toggle-filter-btn {
  font-size: 12px;
  background: whitesmoke;
  position: fixed;
  left: 477px;
  top: 15%;
  z-index: 4;
  transform: rotate(90deg);
  overflow: hidden;
}
.warning-tool-tip {
  background-color: #ffe190;
}
.compitition-page-content {
  display: flex;
  overflow: hidden !important;
  padding-bottom: 45px;
  .shake {
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
  }
  @keyframes shake {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  .search-toolbar {
    z-index: 3;
    background-color: #F9F9F9 !important;
    padding: 130px 20px 50px;
    font-size: 12px;
    height: 100vh;
    overflow-y: scroll;
    transition: .25s ease all;
    flex: 1;
    top: 70px;
    .main-heading {
      text-align: left;
    }
    .clear-filters {
      text-align: left;
      padding-bottom: 8px;
      border-bottom: 1px solid #000;
      margin-bottom: 20px;
    }
    .custom-control-label::before,
    .custom-control-label::after {
      top: 0px !important;
      border-radius: 0px;
    }
    .subtitle {
      text-align: left;
      font-weight: 600;
      font-size: 12px !important;
      color: #000;
      width: 100%;
      justify-content: flex-start !important;
    }
    .date-from,
    .date-to {
      display: block;
    }
    .posting-date-filter {
      .custom-control {
        display: flex;
        margin: 5px 5% 5px;
        ::before,
        ::after {
          top: 0px !important;
          border-radius: 0px;
        }
      }
      .calender-selection {
        text-align: left;
        margin-left: 5%;
      }
    }
    .posting-driver-type {
      .with-include-exclude {
        .job-type-sub-heading {
          .subtitle {
            width: 165px;
            flex: 2 1 0;
          }
          p {
            flex: 1 1 0;
            margin-bottom: 3px;
          }
        }
      }
      .job-type-sub-heading {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        width: 100%;
        p {
          text-align: left;
          font-weight: 400;
          font-size: 12px !important;
          color: #000;
          width: 100%;
          justify-content: flex-start !important;
        }
      }
      label {
        margin-bottom: 0px;
      }
    }
    .company-filter {
      .label-area {
        font-size: 16px;
        font-weight: 500;
        text-align: left;
        display: block;
        margin-bottom: 10px;
      }
      .run-search-button {
        background-color: #70a300;
        color: #fff;
        margin-top: 15px;
        width: 70%;
        &:hover {
          background-color: #628f00 !important;
        }
      }
    }
  }
  .main-container {
    padding: 0 30px;
    text-align: left;
    .first-row-height {
      min-height: 70vh;
    }
    .second-row-height {
      min-height: 80vh;
    }
    h4 {
      margin-bottom: 25px;
    }
    .top-section {
      padding-left: 4%;
    }
    .location-search {
      .location {
        display: flex;
        .primary-run-search-button-wrapper {
          width: 40%;
          height: 15%;
          align-self: center;
          .update-run-search-button {
            background-color: white !important;
            color: #70a300 !important;
            outline: 1px solid #70a300 !important;
            width: 50%;
            &:hover {
              color: #48630c !important;
            }
          }
        }
      }
    }

    .location-search-area {
      width: 100%;
      .location-search-radius {
        display: flex;
        justify-content: space-between;
        label,
        p {
          font-weight: 400;
          color: #000;
          margin-bottom: 0px;
        }
        p {
          cursor: pointer;
        }
      }
    }

    .advertised-salary-distribution {
      .extra-details {
        margin-top: 15px;
        h6 {
          font-weight: 700;
        }
        p {
          color: #000;
          font-weight: 400;
        }
      }
    }
  }
  .side-bar-run-search {
    button {
      background-color: #70a300;
      color: #fff;
      margin-top: 15px;
      width: 70%;
      &:hover {
        background-color: #628f00 !important;
      }
    }
  }
}
.clear-all-filters {
  cursor: pointer;
  padding-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 7px;
  span {
    width: 125px;
  }
}
.job-posting-pills {
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  .pill-button {
    flex: none;
    margin-left: 7px !important;
    margin-top: 3px;
  }
}
.badge-margins {
  margin-bottom: 2rem !important;
}
.badge-font {
  font-size: 1rem !important;
  font-weight: 700 !important;
}
.badge-color {
  color: #abd037 !important;
  background: #444444 !important;
}
.badge-margins {
  margin-bottom: 6rem !important;
}
.padding-none {
  padding: 0px !important;
}
.fw-700 {
  font-weight: 700 !important;
}
.v-btn:focus .v-btn__content,
.v-btn .v-btn__content:focus {
  outline: none !important;
  box-shadow: none !important;
}
.v-btn .v-btn__ripple {
  display: none !important; /* Optional: Remove ripple if you don't want it */
}
.filters-close-btn {
  right: 10px;
  top: 90px;
}
</style>
