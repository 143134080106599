import { render, staticRenderFns } from "./Notifications-FullSize.vue?vue&type=template&id=5fdab7d8&scoped=true"
import script from "./Notifications-FullSize.vue?vue&type=script&lang=js"
export * from "./Notifications-FullSize.vue?vue&type=script&lang=js"
import style0 from "./Notifications-FullSize.vue?vue&type=style&index=0&id=5fdab7d8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fdab7d8",
  null
  
)

export default component.exports