<template>
  <v-app>
    <v-row>
      <v-col cols="12" class="">
        <v-btn
          id="toggle-filter-sb"
          class="mt-3 ml-11"
          @click="isCollapsed = !isCollapsed"
          elevation="0"
          color="#ededed"
        >
          <v-icon
            class=""
            size="14"
            color="#787878"
            ripple="false"
          >mdi-tune-vertical</v-icon> Filters
        </v-btn>
      </v-col>
      <v-col cols="12">
        <MapsMapboxSection
          :selectedStates="selectedStates"
          ref="MapsMapboxSectionComponent"
          :isCollapsed="isCollapsed"
        />
      </v-col>
    </v-row>
    <v-navigation-drawer
        v-model="isCollapsed"
        app
        right
        temporary
        width="300"
        hide-overlay
        style="z-index: 1"
        rounded="false"
    >
      <MapsSideNavBar
            :defaultSelectedStates="defaultSelectedStates"
            :formattedLocationOptions="locationOptions"
            :mapStateChips="stateChips"
            @update:selectedStates="updateSelectedStates"
          />
    </v-navigation-drawer>
  </v-app>
</template>

<script>

import { mapGetters } from 'vuex';
import MapsMapboxSection from './MapsMapboxSection.vue';
import MapsSideNavBar from './MapsSideNavBar.vue';

export default {
  name: 'RecruitingDifficulty',
  data() {
    return {
      isCollapsed: false,
      defaultSelectedStates: [
        'IL', 'IN', 'MI', 'OH', 'WI', 'IA', 'KS', 'MN', 'MO', 'NE',
        'ND', 'SD', 'DE', 'DC', 'MD', 'VA', 'WV', 'NJ', 'NY', 'PA',
        'CT', 'ME', 'MA', 'NH', 'RI', 'VT', 'AR', 'LA', 'OK', 'TX',
        'AL', 'KY', 'MS', 'TN', 'FL', 'GA', 'NC', 'SC', 'AZ', 'CO',
        'ID', 'MT', 'NV', 'NM', 'UT', 'WY', 'CA', 'OR', 'WA',
      ],
      selectedStates: [
        'IL', 'IN', 'MI', 'OH', 'WI', 'IA', 'KS', 'MN', 'MO', 'NE',
        'ND', 'SD', 'DE', 'DC', 'MD', 'VA', 'WV', 'NJ', 'NY', 'PA',
        'CT', 'ME', 'MA', 'NH', 'RI', 'VT', 'AR', 'LA', 'OK', 'TX',
        'AL', 'KY', 'MS', 'TN', 'FL', 'GA', 'NC', 'SC', 'AZ', 'CO',
        'ID', 'MT', 'NV', 'NM', 'UT', 'WY', 'CA', 'OR', 'WA',
      ],
      locationOptions: [],
      stateChips: {},
      metricSelected: 'Recruiting Difficulty Score',
      locationTypeSelected: 'MSA',
      showMapFilters: false,
    };
  },
  components: {
    MapsMapboxSection,
    MapsSideNavBar,
  },
  computed: {
    colSize() {
      return this.isCollapsed ? 12 : 12;
    },
    ...mapGetters({
      stateRegionSubregionOptions: 'locations/getStateRegionSubregionOptions',
    }),
  },
  async mounted() {
    await this.$store.dispatch('locations/fetchStateRegionSubregionOptions');
    this.$store.dispatch('global/addPageLinkHighlightAndNavLinkBorder', 'Locations');
    this.locationOptions = this.formattedLocationOptions();
    this.showMapFilters = this.locationOptions.length > 0
      && Object.keys(this.stateChips).length > 0;
  },
  watch: {
    locationOptions: {
      handler() {
        this.showMapFilters = this.locationOptions.length > 0
          && Object.keys(this.stateChips).length > 0;
      },
      deep: true,
    },
    stateChips: {
      handler() {
        this.showMapFilters = this.locationOptions.length > 0
          && Object.keys(this.stateChips).length > 0;
      },
      deep: true,
    },
  },
  methods: {
    toggleFilter() {
      this.isCollapsed = !this.isCollapsed;
      this.$refs.MapsMapboxSectionComponent.resetTableWidth();
    },
    formattedLocationOptions() {
      // Contiguous states -> regions -> subregions -> states
      // Keep map of pushed objects to avoid searching
      const indexOfRegions = { cont: {}, nonCont: {} };
      const pushedRegions = { cont: {}, nonCont: {} };
      const pushedSubregions = { cont: {}, nonCont: {} };

      const options = [
        {
          id: 'Contiguous United States',
          name: 'Contiguous United States',
          children: [],
        },
        {
          id: 'Non-contiguous States',
          name: 'Non-contiguous States',
          children: [],
        },
      ];

      // Add regions.
      this.stateRegionSubregionOptions.forEach((location) => {
        // Check if region is cont. or non-cont.
        if (location.contiguous_united_states === 1) {
          // Don't push regions if exist
          if (!(location.Region in pushedRegions.cont)) {
            const region = {
              id: location.Region,
              name: location.Region,
              children: [],
            };

            options[0].children.push(region);
            // Keep index to avoid searching for it later
            const index = options[0].children.length - 1;
            indexOfRegions.cont[location.Region] = index;

            pushedRegions.cont[location.Region] = location.Region;
          }
        } else {
          // Don't push regions if exist
          // eslint-disable-next-line no-lonely-if
          if (!(location.Region in pushedRegions.nonCont)) {
            const region = {
              id: location.Region,
              name: location.Region,
              children: [],
            };

            options[1].children.push(region);
            // Keep index to avoid searching for it later
            const index = options[1].children.length - 1;
            indexOfRegions.nonCont[location.Region] = index;

            pushedRegions.nonCont[location.Region] = location.Region;
          }
        }
      });

      // Add Subregions.
      this.stateRegionSubregionOptions.forEach((location) => {
        const stateObj = {
          id: location.State,
          name: location.StateName,
        };

        // Populate map to show selected options as chips
        this.stateChips[stateObj.id] = stateObj.name;

        // Check if subregion is cont. or non-cont.
        if (location.contiguous_united_states === 1) {
          const parentIndex = indexOfRegions.cont[location.Region];

          // Don't push subregions if exist
          if (!(location.Subregion in pushedSubregions.cont)) {
            const subregion = {
              id: location.Subregion,
              name: location.Subregion,
              children: [],
            };

            // Find parent of subregion and push it
            options[0].children[parentIndex].children.push(subregion);
            pushedSubregions.cont[location.Subregion] = location.Subregion;
          }

          // Push state to subregion
          const subregionIndex = options[0].children[parentIndex].children.length - 1;
          options[0].children[parentIndex].children[subregionIndex].children.push(stateObj);
        } else {
          const parentIndex = indexOfRegions.nonCont[location.Region];

          // Don't push subregions if exist
          // eslint-disable-next-line no-lonely-if
          if (!(location.Subregion in pushedSubregions.nonCont)) {
            const subregion = {
              id: location.Subregion,
              name: location.Subregion,
              children: [],
            };

            // Find parent of subregion and push it
            options[1].children[parentIndex].children.push(subregion);
            pushedSubregions.nonCont[location.Subregion] = location.Subregion;
          }

          // Push state to subregion
          const subregionIndex = options[1].children[parentIndex].children.length - 1;
          options[1].children[parentIndex].children[subregionIndex].children.push(stateObj);
        }
      });

      return options;
    },
    updateSelectedStates(updatedStates) {
      this.selectedStates = updatedStates;
    },
  },
};
</script>
<style lang="scss" scoped>
  .collapsed-sidebar-btn {
    left: 16px;
    background-color: #F9F9F9;
    position: fixed;
    top: 118px;
  }
  .expanded-sidebar-btn {
    border-radius: 50%;
    min-width: 36px !important;
    width: 36px !important;
    height: 36px !important;
    left: 15%;
    background-color: #E0E0E0 !important;
    position: absolute;
    top: 20px;
  }
  .sidebar {
    transition: .90s ease all;
  }
  .filters-span {
    text-transform: capitalize;
  }
  .toggle-filter-sb {
    border: 1px solid transparent;
    padding: 12px 12px;
    background-color: #fff;
    border-color: #ccc;
  }

</style>
