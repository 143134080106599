<template>
  <div class="drivers-grid">
    <div class="drivers-filters pt-5" v-if="dataReady">
      <v-container fluid ma-0 pa-0 style="background-color: #f9f9f9;">
        <v-row no-gutters>
          <v-col cols="2" class="p-2 ml-3 month-range-container">
            <MonthRangeCalendar
              @filter-search="filterSearch"
              @setDateRange="setCalendarDateRange"
              ref="monthRangeCalendarChildRef" />
          </v-col>
          <v-col cols="2" class="p-2">
            <FilterSelect
              v-model="driverType"
              class="driver-page-select filter-style"
              id="driver-page-select"
              :emitVal="'driverType'"
              :multiple="false"
              :name="'driver-type'"
              :title="'Driver Type'"
              :label="'Driver Type'"
              :values="driverValues"
              :chosenOne="driverType"
              contentClass="trends-select"
              @driverType="setDriverTypeSelected"
            />
          </v-col>
          <v-col cols="2" class="p-2">
            <FilterSelect
              v-model="haulType"
              class="driver-page-select filter-style"
              id="trends-haul-type-select"
              :emitVal="'haulType'"
              :multiple="false"
              :name="'haul-type'"
              :title="'Haul Type'"
              :label="'Haul Type'"
              :values="haulTypeValues"
              :chosenOne="haulType"
              contentClass="trends-select"
              @haulType="setHaulTypeSelected"
            />
          </v-col>
          <v-col cols="2" class="p-2">
            <FilterSelect
              v-model="routeType"
              class="driver-page-select filter-style"
              id="trends-route-type-select"
              :emitVal="'routeType'"
              :multiple="false"
              :name="'route-type'"
              :title="'Route Type'"
              :label="'Route Type'"
              :values="routeTypeValues"
              :chosenOne="routeType"
              contentClass="trends-select"
              @routeType="setRouteTypeSelected"
            />
          </v-col>
          <v-col cols="2" class="p-2">
            <FilterSelect
              v-model="endorsementRequired"
              class="driver-page-select filter-style"
              id="trends-endorsement-required-select"
              :emitVal="'endorsementRequired'"
              :multiple="false"
              :name="'endorsement-required'"
              :title="'Endorsement Required'"
              :label="'Endorsement Required'"
              :values="endorsementRequiredValues"
              :chosenOne="endorsementRequired"
              contentClass="trends-select"
              @endorsementRequired="setEndorsementRequiredSelected"
            />
          </v-col>
            <v-switch
            class="v-input--reverse"
            label="Show Previous Year Lines"
            v-model="showPreviousYear"
            reverse
            >
            </v-switch>
          <v-spacer></v-spacer>
        </v-row>
      </v-container>
    </div>
    <div v-else>
      <loading-spinner
        class="loading-spinner"
        :loading="!dataReady"
        :color="'#70A300'"
        :size="'150px'"
      />
    </div>
    <div class="trends-page-content">
      <div class="text-h5 ml-8">Current Performance</div>
      <TrendsThreeMonthAverage
        class="drivers-three-month-average-component"
      />
      <div class="text-h5 ml-8">Historical Trends</div>
      <v-row class="my-7">
        <v-col>
          <v-card v-if="!cplData.loading" class="cost-per-lead-card ml-8"
          :class="{'trends-difference-no-data': determineNoData(cplData, 'selectedDatesCPL'),
            'trends-difference-warning-data': determineWarning(cplData, 'selectedDatesCPL')
          }">
            <v-row>
              <v-col data-app>
                <v-tooltip top :disabled="!determineWarning(cplData, 'selectedDatesCPL')
                  && !determineNoData(cplData, 'selectedDatesCPL')">
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <DifferenceBanner
                        class="cost-per-lead-banner"
                        tooltipTitle="Average Cost per Lead"
                        :tooltipTip="avereageCostPerLeadToolTip"
                        :value="cplData.cplBigNumber ? `${cplData.cplBigNumber}` : '-'"
                        :color="'#70A300'"
                        :title="'Average Cost per Lead'"
                        :increase="chartIncrease(0)"
                        :difference="cplData.yoYChange !== '-' ? `${cplData.yoYChange}` : '-'"
                        :differenceFontSize="cplData.yoYChange ? '24px' : '100%'"
                        :percentChange="cplData.yoYPercentChange"
                        :isDollarValue="true"
                        :noData="cplData.emptyData"
                      />
                    </div>
                  </template>
                  <span v-if="determineWarning(cplData, 'selectedDatesCPL')">
                    <p>Some of your selected months have a small sample size,
                    making the data more susceptible to being skewed by outliers.</p>
                    These months are shown with an orange triangle in the line graph.
                    Consider expanding your search criteria.</span>
                    <span v-else><p>Some of your selected months do not capture enough data to
                      display results. Consider expanding your search criteria.</p>
                    Additionally, months showing an orange triangle
                    indicate that the sample size is still small,
                    making the data more susceptible to being skewed by outliers.</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row>
              <v-spacer></v-spacer>
              <v-col class="d-flex mt-auto mb-auto flex-column" cols="10">
                <CostPerLead
                  v-if="!cplData.emptyData && !noChartData(cplData, 'selectedDatesCPL')"
                  class="cost-per-lead-chart"
                  :data="cplData"
                  :key="cplChartKey"
                  :show-previous-year="showPreviousYear"
                />
                <NoData v-else class="cost-per-lead-chart"
                error-message="Not enough data to present chart." />
                <Legend v-if="!cplData.emptyData && !noChartData(cplData, 'selectedDatesCPL')"
                        class="cost-per-lead-legend"
                        :legendValues="cplLegend"
                />
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
          </v-card>
          <v-card v-else class="cost-per-lead-card">
            <loading-spinner
              class="loading-spinner"
              :loading="cplData.loading"
              :color="'#70A300'"
              :size="'150px'"
            />
          </v-card>
        </v-col>
        <v-col>
          <v-card v-if="!cphData.loading" class="cost-per-hire-card"
          :class="{'trends-difference-no-data': determineNoData(cphData, 'selectedDatesCPH'),
            'trends-difference-warning-data': determineWarning(cphData, 'selectedDatesCPH')
          }">
            <v-row>
              <v-col data-app>
                <v-tooltip top :disabled="!determineWarning(cphData, 'selectedDatesCPH')
                  && !determineNoData(cphData, 'selectedDatesCPH')">
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <DifferenceBanner
                        class="cost-per-hire-banner"
                        tooltipTitle="Average Advertising Cost per Hire"
                        :tooltipTip="averageCostPerHireToolTip"
                        :value="
                          cphData.cphBannerData['current_month_cph']
                            ? `${cphData.cphBannerData['current_month_cph']}`
                            : '-'
                        "
                        :color="'#70A300'"
                        :title="'Average Advertising Cost per Hire'"
                        :increase="chartIncrease(0)"
                        :difference="cphData.yoYChange !== '-' ? `${cphData.yoYChange}` : '-'"
                        :differenceFontSize="cphData.yoYChange ? '24px' : '100%'"
                        :percentChange="cphData.cphBannerData['yoy_cph_change_percentage']"
                        :isDollarValue="true"
                        :noData="cphData.emptyData"
                      />
                    </div>
                  </template>
                  <span v-if="determineWarning(cphData, 'selectedDatesCPH')">
                    <p>Some of your selected months have a small sample size,
                    making the data more susceptible to being skewed by outliers.</p>
                    These months are shown with an orange triangle in the line graph.
                    Consider expanding your search criteria.</span>
                    <span v-else><p>Some of your selected months do not capture enough data to
                      display results. Consider expanding your search criteria.</p>
                    Additionally, months showing an orange triangle indicate
                    that the sample size is still small,
                    making the data more susceptible to being skewed by outliers.</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row>
              <v-spacer></v-spacer>
              <v-col class="d-flex mt-auto mb-auto flex-column" cols="10">
                <CostPerHire
                  v-if="!cphData.emptyData && !noChartData(cphData, 'selectedDatesCPH')"
                  class="cost-per-hire-chart"
                  :data="cphData"
                  :key="cphChartKey"
                  :show-previous-year="showPreviousYear"
                />
                <NoData v-else class="cost-per-hire-chart"
                error-message="Not enough data to present chart." />
                <Legend v-if="!cphData.emptyData && !noChartData(cphData, 'selectedDatesCPH')"
                        class="cost-per-hire-legend"
                        :legendValues="cplLegend"
                />
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
          </v-card>
          <v-card v-else class="cost-per-hire-card">
            <loading-spinner
              class="loading-spinner"
              :loading="cphData.loading"
              :color="'#70A300'"
              :size="'150px'"
            />
          </v-card>
        </v-col>
      </v-row>
      <v-row style="margin-top: 1%;" class="justify-center">
        <v-col lg="6" class="d-flex align-center h-100">
          <v-card width="100%" v-if="!lthData.loading" class="lead-to-hire-card"
          :class="{'trends-difference-no-data': determineNoData(cphData, 'selectedDatesCPH'),
            'trends-difference-warning-data': determineWarning(cphData, 'selectedDatesCPH')
          }">
            <v-row>
              <v-col data-app>
                <v-tooltip top :disabled="!determineWarning(cphData, 'selectedDatesCPH')
                  && !determineNoData(cphData, 'selectedDatesCPH')">
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <DifferenceBanner
                        class="lead-to-hire-banner"
                        tooltipTitle="Average Lead-to-Hire Ratio"
                        :tooltipTip="averageLeadToHireToolTip"
                        :value="lthBannerData ? `${lthBannerData.bigNumber}` : '-'"
                        :color="'#70A300'"
                        :title="'Average Lead-to-Hire Ratio'"
                        :increase="lthSelectedMoreThanPreviousYear"
                        :difference="
                          lthBannerData.yOY !== '-'
                            ? `${Math.abs(Math.round(lthBannerData.yOY * 100))}%`
                            : 'No YoY Data Available'
                        "
                        :differenceFontSize="lthBannerData.yOY ? '24px' : '100%'"
                        :isDollarValue="false"
                        :noData="cphData.emptyData"
                      />
                    </div>
                  </template>
                  <span v-if="determineWarning(cphData, 'selectedDatesCPH')">
                    <p>Some of your selected months have a small sample size,
                    making the data more susceptible to being skewed by outliers.</p>
                    These months are shown with an orange triangle in the line graph.
                    Consider expanding your search criteria.</span>
                    <span v-else><p>Some of your selected months do not capture enough data to
                      display results. Consider expanding your search criteria.</p>
                    Additionally, months showing an orange triangle indicate that the sample
                    size is still small,
                    making the data more susceptible to being skewed by outliers.</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row>
              <v-spacer></v-spacer>
              <v-col class="d-flex mt-auto mb-auto flex-column" cols="10">
                <LeadToHire
                  v-if="!cphData.emptyData && !noChartData(cphData, 'selectedDatesCPH')"
                  class="lead-to-hire-chart"
                  :data="lthData"
                  :key="lthChartKey"
                  :show-previous-year="showPreviousYear"
                />
                <NoData v-else class="lead-to-hire-chart"
                error-message="Not enough data to present chart."/>
                <Legend class="lead-to-hire-legend"
                        v-if="!cphData.emptyData && !noChartData(cphData, 'selectedDatesCPH')"
                        :legendValues="cplLegend"
                />
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
          </v-card>
          <v-card v-else class="lead-to-hire-card">
            <loading-spinner
              class="loading-spinner"
              :loading="lthData.loading"
              :color="'#70A300'"
              :size="'150px'"
            />
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LoadingSpinner from '../StyledComponents/LoadingSpinner.vue';
import DifferenceBanner from '../DifferenceBanner.vue';
import CostPerLead from '../Charts/Trends/CostPerLead.vue';
import CostPerHire from '../Charts/Trends/CostPerHire.vue';
import FilterSelect from '../StyledComponents/FilterSelect.vue';
import LeadToHire from '../Charts/Trends/LeadToHire.vue';
import Legend from '../Charts/StyledComponents/Legend.vue';
import NoData from '../Charts/StyledComponents/NoData.vue';
import TrendsThreeMonthAverage from './TrendsThreeMonthAverage.vue';
import MonthRangeCalendar from './MonthRangeCalendar.vue';
import {
  formatDate,
} from '../../helpers/date-utilities';

export default {
  name: 'Trends',
  components: {
    MonthRangeCalendar,
    DifferenceBanner,
    CostPerLead,
    CostPerHire,
    FilterSelect,
    LoadingSpinner,
    LeadToHire,
    Legend,
    NoData,
    TrendsThreeMonthAverage,
  },
  data() {
    return {
      driverValues: [],
      haulTypeValues: [],
      routeTypeValues: [],
      endorsementRequiredValues: [],
      driverTypeChanged: false,
      haulTypeChanged: false,
      routeTypeChanged: false,
      endorsementRequiredChanged: false,
      cplLegend: [
        { title: 'Selected Dates', color: '#70A300' },
        { title: 'Previous Year', color: '#C4C4C3' },
      ],
      refreshCPLChart: false,
      showPreviousYear: true,
      selectedTimeframe: null,
      yearOptions: [],
      monthOptions: [],
      startingDate: null,
      endingDate: null,
      driverType: 'All',
      routeType: 'All',
      haulType: 'All',
      endorsementRequired: 'None',
      selectedDate: null,
      driverTypeSelected: null,
      pageValue: {
        link: {
          title: 'market',
          linkId: 'Trends',
          mobileID: 'mdrivers',
          section: 'driver',
        },
      },
      performanceTrendsMenu: [
        {
          title: 'Share',
          link: '/market/locations',
        },
      ],
      dataReady: false,
      avereageCostPerLeadToolTip:
        'Average lead costs are figured by aggregating stats across all Randall Reilly truck driver recruiting digital marketing campaigns that match the search criteria. <br/> <br/> <em>Multi-carrier applications are not included in this calculation.</em>',
      averageCostPerHireToolTip:
        'Average hire costs are figured by aggregating stats across the Randall Reilly truck driver recruiting digital marketing campaigns that match the search criteria. <br/> <br/> <em>Stats are only compiled from carriers that provide their hire data to Randall Reilly.</em>',
      averageLeadToHireToolTip:
        'Average lead-to-hire ratios are figured by aggregating stats across the Randall Reilly truck driver recruiting digital marketing campaigns that match the search criteria. <br/> <br/> Stats are only compiled from carriers that provide their hire data to Randall Reilly.  <br/> <br/> Better lead-to-hire ratios (lower numbers) are closer to the x-axis (bottom) of the chart, so as the lead-to-hire ratio improves, the line sinks lower.',
    };
  },
  async created() {
    let data = sessionStorage.getItem('driverPageSearch');
    data = JSON.parse(data);

    if (!data) {
      const startingDate = formatDate(new Date(), 'YYYY/MM/DD', { subtractYears: 1, startOfMonth: 1 });
      const endingDate = formatDate(new Date(), 'YYYY/MM/DD', { startOfMonth: 1 });
      data = {
        starting_date: startingDate,
        ending_date: endingDate,
        driver_type: this.driverType,
        haul_type: this.haulType,
        route_type: this.routeType,
        endorsement_required: this.endorsementRequired,
      };
      sessionStorage.setItem('driverPageSearch', JSON.stringify(data));
      this.startingDate = startingDate;
      this.endingDate = endingDate;
    } else {
      this.startingDate = data.starting_date;
      this.endingDate = data.ending_date;
      this.driverType = data.driver_type;
      this.haulType = data.haul_type;
      this.routeType = data.route_type;
      this.endorsementRequired = data.endorsement_required;
    }
    this.driverType = data.driver_type;
    this.dataReady = true;
    this.$store.commit('trends/setMostRecentMonthTrendsLoading', true);
    await this.$store.dispatch('trends/getMostRecentMonthTrends', data);
    await this.$store.dispatch('trends/getCPLData', data);
    await this.$store.dispatch('trends/getCPHData', data);
    await this.$store.dispatch('trends/getLTHData', data);
  },
  async mounted() {
    const data = JSON.stringify(this.pageValue.link);
    sessionStorage.setItem('lastPageViewed', data);
    await this.$store.dispatch('global/addPageLinkHighlightAndNavLinkBorder', this.pageValue);

    this.driverValues = this.$store.state.trends.driverValues;

    // Date cannot be set from created as refs are available in mounted
    // Other parameters are set in created.
    this.$refs.monthRangeCalendarChildRef.updateDateRange(this.startingDate, this.endingDate);

    this.driverValues = this.$store.state.trends.driverValues;
    this.haulTypeValues = this.$store.state.trends.haulTypeValues;
    this.routeTypeValues = this.$store.state.trends.routeTypeValues;
    this.endorsementRequiredValues = this.$store.state.trends.endorsementRequiredValues;
    this.$cable.subscribe({
      channel: 'NotificationChannel',
    });

    // Set the date range
    const startingDate = formatDate(new Date(), 'YYYY-MM-DD', { subtractYears: 1, startOfMonth: 1 });
    const endingDate = formatDate(new Date(), 'YYYY-MM-DD', { startOfMonth: 1 });
    this.setCalendarDateRange([startingDate, endingDate]);
  },
  destroyed() {
    this.$destroy();
  },
  computed: {
    ...mapGetters({
      threeMonthBannerData: 'trends/getThreeMonthBannerData',
      cphData: 'trends/getCphData',
      cphChartKey: 'trends/getCphChartKey',
      lthData: 'trends/getLthData',
      cplData: 'trends/getCplData',
      cplChartKey: 'trends/getCplChartKey',
      lthChartKey: 'trends/getLthChartKey',
    }),
    lthBannerData() {
      return {
        bigNumber: this.$store.state.trends.lthData.lthBigNumber,
        yOY: this.$store.state.trends.lthData.yoYChange,
      };
    },
    cplBannerData() {
      return {
        bigNumber: this.$store.state.trends.cplData.cplBigNumber,
        yOY: this.$store.state.trends.cplData.yoYChange,
      };
    },
    // eslint-disable-next-line vue/return-in-computed-property
    lthSelectedMoreThanPreviousYear() {
      if (!this.lthData.emptyData) {
        return parseFloat(this.lthBannerData.yOY) > 0;
      }
    },
  },
  methods: {
    noChartData(dataObject, attribute) {
      return dataObject[attribute].filter((attr) => attr !== null).length === 0;
    },
    determineWarning(dataObject, attribute) {
      if (dataObject[attribute].includes(null)) {
        return false;
      }
      const minimumCampaignCount = Math.min(...dataObject.selectedYearCampaignCount);
      const minimumSpendAmount = Math.min(...dataObject.selectedYearSpendAmount);
      // eslint-disable-next-line max-len
      return (minimumCampaignCount < 8 && minimumCampaignCount >= 3) || (minimumSpendAmount < 50000 && minimumSpendAmount >= 20000);
    },
    determineNoData(dataObject, attribute) {
      if (dataObject[attribute].includes(null)) {
        return true;
      }
      const minimumCampaignCount = Math.min(...dataObject.selectedYearCampaignCount);
      const minimumSpendAmount = Math.min(...dataObject.selectedYearSpendAmount);
      // eslint-disable-next-line max-len
      return minimumCampaignCount < 3 || minimumSpendAmount < 20000 || dataObject.selectedYearCampaignCount.length === 0 || dataObject.selectedYearSpendAmount.length === 0;
    },
    setCalendarDateRange(value) {
      this.selectedTimeframe = value;
      this.startingDate = `${value[0]}`;
      this.endingDate = `${value[1]}`;
    },
    chartIncrease(yoyChange) {
      let yOY = Number(yoyChange);

      if (Number.isNaN(yOY)) {
        yOY = parseFloat(yoyChange.replace(/,/g, ''));
      }

      if (yOY && yOY > 0) {
        return true;
      }

      return false;
    },
    setDriverTypeSelected(value) {
      const data = JSON.parse(sessionStorage.getItem('driverPageSearch'));
      if (data.driver_type !== value) {
        this.driverTypeChanged = true;
      } else {
        this.driverTypeChanged = false;
      }
      this.driverType = value;
      this.filterSearch();
    },
    setHaulTypeSelected(value) {
      const data = JSON.parse(sessionStorage.getItem('driverPageSearch'));
      if (data.haulType !== value) {
        this.haulTypeChanged = true;
      } else {
        this.haulTypeChanged = false;
      }
      this.haulType = value;
      this.filterSearch();
    },
    setRouteTypeSelected(value) {
      const data = JSON.parse(sessionStorage.getItem('driverPageSearch'));
      if (data.routeType !== value) {
        this.routeTypeChanged = true;
      } else {
        this.routeTypeChanged = false;
      }
      this.routeType = value;
      this.filterSearch();
    },
    setEndorsementRequiredSelected(value) {
      const data = JSON.parse(sessionStorage.getItem('driverPageSearch'));
      if (data.endorsementRequired !== value) {
        this.endorsementRequiredChanged = true;
      } else {
        this.endorsementRequiredChanged = false;
      }
      this.endorsementRequired = value;
      this.filterSearch();
    },
    async filterSearch() {
      const data = {
        starting_date: this.startingDate,
        ending_date: this.endingDate,
        driver_type: this.driverType,
        haul_type: this.haulType,
        route_type: this.routeType,
        endorsement_required: this.endorsementRequired,
      };
      sessionStorage.setItem('driverPageSearch', JSON.stringify(data));
      if (this.driverTypeChanged || this.haulTypeChanged
        || this.routeTypeChanged || this.endorsementRequiredChanged) {
        await this.$store.dispatch('trends/getMostRecentMonthTrends', data);
      }
      await this.$store.dispatch('trends/getCPLData', data);
      await this.$store.dispatch('trends/getCPHData', data);
      await this.$store.dispatch('trends/getLTHData', data);
      this.driverTypeChanged = false;
      this.haulTypeChanged = false;
      this.routeTypeChanged = false;
      this.endorsementRequiredChanged = false;
    },
  },
};
</script>
<style scoped>
.trends-page-content {
  margin-top: 5%;
  width: 100%;
}
  .month-range-container .v-text-field .v-label {
    top: 0 !important;
  }
  .drivers-grid {
    height: 100%;
    margin-bottom: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .drivers-filters {
    width: 100%;
    min-height: 75px;
    position: sticky;
    left: 2.9%;
    position: -webkit-sticky;
    top: 70px;
    z-index: 2;
    background: #f9f9f9;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .filter-style {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    /*padding: 20px;*/
  }
  .filter-search-button {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 100px;
  }
.drivers-three-month-average-component {
  width: 70%;
  margin-left: 15%;
  margin-top: 1%;
}
.lead-to-hire-chart {
  height: 300px;
}
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}
.performance-trends-ellipsis {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.cost-per-lead-banner {
  justify-content: center;
  align-content: center;
}
.cost-per-lead-legend {
  display: flex;
  justify-content: center;
  align-items: center;
}
.lead-to-hire-banner {
  justify-content: center;
  align-content: center;
}
.lead-to-hire-legend {
  display: flex;
  justify-content: center;
  align-items: center;
}
.cost-per-lead-chart {
  height: 300px;
}
.cost-per-hire-banner {
  justify-content: center;
  align-content: center;
}
.cost-per-hire-chart {
  height: 300px;
}
.cost-per-hire-legend {
  display: flex;
  justify-content: center;
  align-items: center;
}
.driver-type {
  top: 175px;
  left: 188px;
  background-color: white;
}
</style>
