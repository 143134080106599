<template>
  <div>
    <v-app-bar
      color="#f6f6f6"
      fixed
      height="80px"
      class="navbar-fullsize"
      style="box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.2); background: #fff;"
    >
      <!-- <div>
          <router-link
            class="ml-6 col col-auto"
            v-if="competitionPageSelected === 'Driver'"
            id="market"
            :to="{ name: 'Trends' }"
            @click.native="makeActiveNavItem('Trends')"
          >
            MARKET TRENDS
          </router-link>
          <router-link
            class="location-navlink col col-auto"
            v-if="competitionPageSelected === 'Driver'"
            id="locations"
            :to="{ name: 'Locations' }"
            @click.native="makeActiveNavItem('Locations')"
          >
            LOCATIONS
          </router-link>
          <router-link
            class="competition-navlink col col-auto"
            :id="`Job Postings ${competitionPageSelected}`"
            :to="{ name: `Job Postings ${competitionPageSelected}` }"
            @click.native="makeActiveNavItem(`Job Postings ${competitionPageSelected}`)"
          >
            COMPETITION
          </router-link>
          <router-link
            v-if="competitionPageSelected === 'Mechanic'"
            class="competition-navlink col col-auto"
            id="Hourly Wages"
            :to="{ name: `Hourly Wages` }"
            @click.native="makeActiveNavItem(`Hourly Wages`, true)"
          >
            REPORTED WAGES & EMPLOYMENT
          </router-link>
      </div> -->
      <v-spacer></v-spacer>
      <v-row class="str-header-right pr-5" style="flex: none;">
        <v-col cols="auto">
          <!-- <v-select
              variant="outlined"
              :key="key"
              style="max-width: 200px; display: flex; margin-top: 25px;"
              class="driver-or-mechanic-select"
              dense
              outlined
              color="#52575a"
              density="compact"
              @change="handleDataChange"
              :menu-props="{offsetY: true, 'content-class': 'driver-or-mechanic-select'}"
              v-model="selectedItem"
              :items="driverOrMechancisSelectItems"
          ></v-select> -->
        </v-col>
        <a class="str-header-menu-button " :href="stratasWelcomeUrl">
          <span class="material-symbols-outlined">home</span>
        </a>
        <a class="str-header-menu-button str-header-knowledge-base">
          <span class="material-symbols-outlined">school</span>
        </a>
        <a class="str-header-menu-button str-header-help">
            <span
              ref="helpButton"
              @click="showModalDialog"
              aria-label="Help Icon"
              class="material-symbols-outlined"
            >
              help_outline
            </span>
        </a>

        <!-- <Notifications /> -->
        <v-col cols="auto" class="str-header-user-container ml-1 pa-0 pl-4">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
                <!--  eslint-disable-next-line max-len -->
                <p class="str-header-user-details" style="font-weight: 500;font-size: 14px;" v-bind="attrs" v-on="on" >
                  {{  userEmail }}
                  <v-icon class="ma-0">mdi-account-circle-outline</v-icon>
                  <v-icon class="">mdi-chevron-down</v-icon>
                </p>
            </template>
            <div class="str-header-user-drawer">
              <a>User Preferences</a>
              <a @click.stop.prevent="logout">Logout</a>
            </div>
          </v-menu>
        </v-col>

      </v-row>
    </v-app-bar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
// import WaffleMenu from './WaffleMenu.vue';
// import Notifications from './Notifications-FullSize.vue';
import setDialogPosition from '../../helpers/contact-us-dialog';
import { getInstance } from '../../services/AuthService';

export default {
  name: 'NavBar-FullSize',
  props: {
    isAdmin: { type: Boolean, required: false },
  },
  components: {
    // WaffleMenu,
    // Notifications,
  },
  data() {
    return {
      competitionDataType: 'Driver Insights',
      previousSelection: 'Driver Insights',
      key: 0,
      driverOrMechancisSelectItems: ['Driver Insights', 'Mechanic Insights'],
      items: ['Market Trends', 'Locations', 'Competition'],
      selectedItem: null,
      userEmail: '',
      stratasWelcomeUrl: process.env.VUE_APP_STRATAS_WELCOME_URL,
    };
  },
  mounted() {
    [this.selectedItem] = this.driverOrMechancisSelectItems;
    this.getEmail();
  },
  created() {
    this.competitionDataType = `${this.competitionPageSelected} Insights`;
    this.previousSelection = `${this.competitionPageSelected} Insights`;
  },
  computed: {
    ...mapGetters({
      competitionPageSelected: `global/getDriverOrMechanicData`,
      userRolesObject: 'auth/getUserRolesObject',
    }),
  },
  methods: {
    async getEmail() {
      const auth0Client = getInstance();
      this.userEmail = auth0Client.user.email;
    },
    hasAccess(typeOfAccessRequested) {
      const auth = getInstance();
      // eslint-disable-next-line max-len
      const roles = Object.keys(auth.user?.auth0_permissions?.permissions_modules?.strategize) || [];
      const requestedRole = this.userRolesObject[typeOfAccessRequested];
      if (roles.includes(requestedRole)) {
        return true;
      }
      return false;
    },
    handleDataChange(selection) {
      if (this.hasAccess(selection.split(' ')[0].toLowerCase())) {
        this.$router.push(`/${selection.split(' ')[0].toLowerCase()}/competition/job-postings`);
        this.$store.commit('global/setDriverOrMechanicData', selection.split(' ')[0]);
        this.previousSelection = selection;
        sessionStorage.setItem('lastPageViewed', JSON.stringify({ linkId: `Job Postings ${selection.split(' ')[0]}` }));
      } else {
        this.competitionDataType = this.previousSelection;
        this.$store.commit('auth/setRestrictPageAccess', true);
        this.$store.commit('auth/setRestrictedPageAccessType', selection.split(' ')[0]);
      }
      this.key += 1;
    },
    showModalDialog() {
      // Removes focus from help icon
      // this.$refs.helpButton.$el.blur();
      // eslint-disable-next-line no-undef
      createHubspotForm();
      setDialogPosition();
    },
    logout() {
      const auth = getInstance();
      auth.logout();
    },
    goToAdminPage() {
      let route = { linkId: 'Admin' };
      route = JSON.stringify(route);
      sessionStorage.setItem('lastPageViewed', route);
      this.$router.push({ name: 'Admin' });
    },
    makeActiveNavItem(data, mechanicPage = false) {
      const determineMechanic = data.includes('Mechanic') || mechanicPage;
      let route = { linkId: data, section: determineMechanic ? 'mechanic' : 'driver' };
      route = JSON.stringify(route);
      sessionStorage.setItem('lastPageViewed', route);
    },
  },
};
</script>

<style lang="scss" scoped>
.mdi {
  margin-top: -4px !important;
}
.v-menu__content {
  box-shadow: none;
}
.str-header-user-drawer {
  position: relative;
}
header {
  i.v-icon,
  .material-symbols-outlined {
    color: #52575a;
  }
}
  .navbar-fullsize {
    display: block !important;
    z-index: 100 !important;
    color: #52575a;
    box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.2);

    a {
      color: #107284 !important;
      font-weight: 500;
      text-transform: uppercase;
      text-decoration: none;
      font-size: 14px;
    }
  }
  .waffle-menu {
    img {
      margin: 10px;
    }
  }
  .stratas-logo {
    content: '';
    background-image: url('../../assets/Stratas-Logo-Color.svg');
    background-repeat: no-repeat;
    height: 35px;
    width: 150px;
    margin-left: 20%;
  }
  .stratas-logo-background {
    background-color: #5a5959;
    width: 100%;
    height: 100%;
  }
  .strategize-logo-spot {
    display: contents;
  }
  .strategize-logo {
    content: '';
    background-image: url('../../assets/Strategize-Horizontal-white-TM.svg');
    background-repeat: no-repeat;
    height: 52%;
    margin: 14px -18px 18px 30px;
    width: 10%;
  }
  .stratas-logo {
    content: '';
    background-image: url('../../assets/Stratas-Logo-Color.svg');
    background-repeat: no-repeat;
    height: 35px;
    width: 150px;
    margin-left: 20%;
  }
  .stratas-logo-background {
    background-color: #5a5959;
    height: 100%;
    width: 250px;
  }

  .topbar-grouping {
    background: none !important;
  }
  .v-application p {
    margin-bottom: 0;
  }
  .v-list-item__title {
    cursor: pointer;
  }
  .v-list-item.theme--light {
    border-bottom: none !important;
    a {
      margin: 10px 20px;
      text-decoration: none;
      font-family: Roboto;
      font-size: 12px;
      font-weight: 500;
      color: #107284!important;
      height: 22px;
      cursor: pointer;
      white-space: nowrap;

      &.is-active {
        // border-bottom: 0.1rem solid #70a300;
        opacity: 1;
      }
      &:hover {
        text-decoration: none;
      }
    }
  }
  .str-header-user-details {
    font-weight: 500;
    font-size: 14px;
    color: #52575a;

    &:hover {
      color: #107284;
      .v-icon {
        color: inherit;
      }
    }
  }
  .v-icon,
  .material-symbols-outlined {
    cursor: pointer;
    color: #52575a;
    &:hover {
      color: #107284;
    }
  }
</style>
