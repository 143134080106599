<template>
  <!-- eslint-disable -->
  <aside
      class="str-sb"
      :class="openSidebar ? 'open' : 'closed'"
  >
      <!-- Header -->
      <div class="str-sb-header">
          <!-- Logo -->
           
          <a :href="stratasWelcomeUrl" class="str-sb-logo">
              <!-- Desktop Logo -->
              <div class="str-sb-logo-desktop">
                  <img :src="logoSrc" alt="Stratas Logo" />
              </div>

              <!-- Mobile Logo -->
              <div class="str-sb-logo-mobile">
                  <img :src="imageUrl" alt="Stratas Logo" />
            </div>
          </a>

          <!-- Mobile Sidebar Toggle Button -->
          <button @click="openSidebar = !openSidebar" class="mobile-toggle">
              <span class="material-symbols-outlined">menu</span>
          </button>
      </div>

      <div
          :class="{ 'block': !showCollapsedIcons, 'openSidebar': showCollapsedIcons }"
          class="str-sb-content"
      >
          <!-- Sidebar Content -->
          <nav class="str-sb-nav">
              <div v-for="(section, index) in sections" :key="index" class="str-sb-section">
                  <!-- Section Title -->
                  <button
                      @click="toggleSection(index)"
                      v-show="openSidebar"
                      class="str-sb-section-title"
                  >
                      {{ section.title }}
                      <!-- <span class="material-symbols-outlined  str-sb-lg-openSidebar" :class="{ 'icon-rotate-180': section.isOpen }">
                          expand_more
                      </span> -->
                  </button>

                  <!-- Section Items -->
                  <div v-show="section.isOpen || windowWidth >= 1024">
                      <div
                          v-for="(item, i) in section.items"
                          :key="i"
                          class="str-sb-item"
                          :class="{
                            'access-denied': !hasAccess(item) && !item.children,
                            'promotion-denied': !shouldShowItem(item) && !item.children,
                            'str-sb-link-single': !item.children
                          }"
                          @mouseenter="showTooltip($event, item.label)"
                          @mouseleave="hideTooltip"
                      >
                          <a
                              v-if="!item.children"
                              v-show="shouldShowItem(item)"
                              :href="item.route"
                              :class="[linkStyles, openSidebar ? 'str-sb-link' : '']"
                          >
                              <span class="material-symbols-outlined str-sb-icon">
                                  {{ item.icon }}
                              </span>
                              <span v-show="openSidebar">{{ item.label }}</span>
                          </a>
                          <a
                              v-if="!hasAccess(item) && shouldShowItem(item) && !item.children"
                              v-show="openSidebar"
                              :href=stratasUnlockUrl
                              class="str-button-unlock"
                              target="_blank"
                              rel="noopener noreferrer"
                          >
                              Unlock
                          </a>
                          <!-- Clickable Icon for Collapsed State -->
                          <a v-if="item.children" class="str-sb-link">
                              <button
                                  v-show="!openSidebar"
                                  @click.stop.prevent="expandSidebarAndToggle(index, i)"
                                  class="material-symbols-outlined str-sb-icon"
                                  @mouseenter="showTooltip($event, item.label)"
                              >
                                  {{ item.icon }}
                              </button>
                              <span v-show="openSidebar" class="material-symbols-outlined str-sb-icon">
                                {{ item.icon }}
                              </span>
                              <span @click.stop="toggleItem(index, i)" v-show="openSidebar">{{ item.label }}</span>

                              <button
                                  v-show="item.children && openSidebar"
                                  @click="toggleItem(index, i)"
                                  class="material-symbols-outlined icon-expand"
                                  :class="{ 'rotate-180': item.isOpen }"
                              >
                                <span class="material-symbols-outlined">
                                    keyboard_arrow_down
                                </span>
                              </button>
                          </a>

                          <!-- Nested Links -->
                          <div v-if="item.children && item.isOpen" v-show="item.isOpen" class="str-sb-link-group">
                              <div v-for="(child, j) in item.children" :key="j">
                                  <!-- <template v-if="child.group && child.group.title !== previousGroupHeader">
                                      <h4 class="str-sb-group-header " :class="child.group.classes">{{ child.group.title }}</h4>
                                      <span v-if="updateGroupHeader(child.group.title)"></span>
                                  </template> -->
                                  <div class="str-sb-link-child">
                                      <!-- Render the link normally if accessible (or disabled if not) -->
                                      <a
                                          :href="hasAccess(child) ? child.route : '#'"
                                          :class="[ hasAccess(child) ? 'access-granted' : 'access-denied' ]"
                                          v-show="openSidebar"
                                          v-bind="!hasAccess(child) ? { title: 'You do not have access to this link', 'aria-disabled': 'true' } : {}"
                                      >
                                          <span class="material-symbols-outlined mr-1">{{ child.icon }}</span>
                                          {{ child.label }}
                                      </a>

                                      <!-- If the child is not accessible, show the unlock button -->
                                      <a
                                          v-if="!hasAccess(child)"
                                          v-show="openSidebar"
                                          :href="stratasUnlockUrl"
                                          class="str-button-unlock ml-2 text-xs font-medium text-[#93d8e4] border-2 border-[#93d8e4] px-2 py-0.5 rounded-full uppercase hover:bg-[#d6d6b3] transition duration-200"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                      >
                                          Unlock
                                      </a>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </nav>
      </div>

      <!-- Collapse Button -->
      <div class="str-button-collapse">
          <button @click="toggleSidebar">
                  <span class="material-symbols-outlined icon">
                      {{ openSidebar ? 'keyboard_double_arrow_left' : 'keyboard_double_arrow_right' }}
                  </span>
                  <span class="text-sm">{{ openSidebar ? 'Collapse' : '' }}</span>
          </button>
      </div>

      <!-- Tooltip -->
      <div
          v-show="tooltip.visible"
          class="stratas-tooltip elevation-6"
          :style="{ top: `${tooltip.top}px`, left: `${tooltip.left}px`, zIndex: 10000, opacity: tooltip.visible ? 1 : 0 }"
      >
          <span>{{ tooltip.text }}</span>
      </div>
  </aside>
</template>

<script>
/* eslint-disable */
import { ref, onMounted, onUnmounted, getCurrentInstance } from "vue";
import { getInstance } from '../../services/AuthService';
import { permission } from "process";

export default {
  data() {
      return {
        //   openSidebar: window.matchMedia('(min-width: 1024px)').matches,
          showCollapsedIcons: false,
          cdnBaseUrl: process.env.VUE_APP_STRATAS_THEME_CDN,
          stratasWelcomeUrl: process.env.VUE_APP_STRATAS_WELCOME_URL,
          userPermissions: {},
          stratasUnlockUrl: process.env.VUE_APP_STRATAS_UNLOCK_URL,
          logoPath:'/images/logo/logo-stratas.svg',
      };
  },
  computed: {
      logoSrc() {
          return this.openSidebar
                 ? `${this.cdnBaseUrl}/images/logo-stratas.svg`
                 : `${this.cdnBaseUrl}/images/logo-stratas-icon.svg`;
      },
      imageUrl() {
          return `${this.cdnBaseUrl}${this.logoPath}`;
      }
  },
  setup(props, { emit })  {
      const auth = getInstance();
      const userPermissions = auth.user?.auth0_permissions?.permissions_modules
      const userRoles = auth.user?.auth0_roles;
      const userBlockedModules = auth.user?.auth0_permissions?.restrictions?.blocked_modules;
      const openSidebar = ref(window.innerWidth >= 1024);
      const windowWidth = ref(window.innerWidth);
      const tooltip = ref({ visible: false, text: "", top: 0, left: 0 });
      const sections = ref([]);
      const sectionStyles = ref("");
      const linkStyles = ref("");
      const nestedLinkStyles = ref("");
      const previousGroupHeader = ref(null);
      const cdnBaseUrl = process.env.VUE_APP_STRATAS_THEME_CDN; // Define your CDN base URL her
      const that = getCurrentInstance().proxy;

      // Fetch the JSON configuration
      const fetchNavigationConfig = async () => {
          try {
              // Fetch the JSON configuration from the CDN
              const response = await fetch(`${cdnBaseUrl}/json/navigation-sidebar.json`);
              const data = await response.json();

              // Update the reactive state with the fetched navigation data
              sections.value = data.sidebar.sections;

            //   console.log("analyze_reports_view " + hasAccess('analyze','analyze_reports_view'));

              // Load the CDN CSS dynamically
              loadCdnCss(`${cdnBaseUrl}/css/stratas-theme.css`);
          } catch (error) {
              console.error("Failed to load navigation configuration:", error);
          }
      };

      // ✅ Function to dynamically load the CDN CSS
      const loadCdnCss = (cssUrl) => {
          if (!document.querySelector(`link[href="${cssUrl}"]`)) {
              const link = document.createElement("link");
              link.rel = "stylesheet";
              link.href = cssUrl;
              document.head.appendChild(link);
              console.log("CDN CSS Loaded:", cssUrl);
          }
      };

      const hasAccess = (item) => {
          if (!item.access) return true; // No access requirements = Always visible

          const { module, permissions, roles } = item.access;
          // // Check module & permission access
          const hasModuleAccess = permissions && permissions.length
                                  ? permissions.some(permission => userHasPermission(module, permission))
                                  : userHasModule(module);

          // // Check role access
          const hasRoleAccess = roles ? roles.some(role => userHasRole(role)) : true;
          // console.log(`Checking access for ${item.label}:`, { hasModuleAccess, hasRoleAccess });

          const isModuleBlocked = userModuleIsBlocked(module);

          if(module === 'reach_campaigns') {
            return !isModuleBlocked;
          }

          // return true;
          // Return true only if both access checks pass
          return hasModuleAccess && hasRoleAccess && !isModuleBlocked;
      };

      // Show or hide based on access and promotion
      const shouldShowItem = (item) => {
          return hasAccess(item) || item.promotion;
      };

      const userHasPermission = (module, permission) => {
          if(userPermissions[module] === undefined) {
            return false;
          }

          const modulePermissions = userPermissions[module];
          const hasPermission = (modulePermissions[permission] === undefined)? false : modulePermissions[permission];
          return hasPermission;
      }

      const userHasModule = (module) => {
          return (userPermissions[module] !== undefined && userPermissions[module] !== false);
      }

      const userHasRole = (role) => {
          return  userRoles.includes(role);
      }

      const userModuleIsBlocked = (module) => {
        return (userBlockedModules[module] !== undefined);
      }

      const updateSidebarState = () => {
        //   openSidebar.value = window.innerWidth >= 1024;
          windowWidth.value = window.innerWidth;
      };

      const loadSidebarStateFromLocalStorage = () => {
          const storedSidebarState = localStorage.getItem('stratasSidebarState');
          if (storedSidebarState !== null) {
              openSidebar.value = JSON.parse(storedSidebarState);
          } else {
              openSidebar.value = window.innerWidth >= 1024;
          }
      };

      const storeSidebarStateInLocalStorage = () => {
          localStorage.setItem('stratasSidebarState', JSON.stringify(openSidebar.value));
      };

      const toggleSidebar = () => {
          emit('toggle-drawer')
          openSidebar.value = !openSidebar.value;
          storeSidebarStateInLocalStorage(); 

          if (!openSidebar.value) {
              // Collapse all expanded sections and child links
              sections.value.forEach(section => {
                that.$set(section, 'isOpen', false);

                  section.items.forEach(item => {
                     that.$set(item, 'isOpen', false);
                  });
              });
          }
      };

      const handleItemClick = (sectionIndex, itemIndex, hasChildren) => {
          if (hasChildren) {
              toggleItem(sectionIndex, itemIndex);
          } else if (!openSidebar.value) {
              openSidebar.value = true; // Expand sidebar if collapsed when clicking a nested link
          }
      };

      const toggleSection = (index) => {
          if (!openSidebar.value) {
              openSidebar.value = true;
              setTimeout(() => {
                  sections.value[index].isOpen = true;
              }, 300);
          } else {
             if(sections.value[index].isOpen != null && sections.value[index].isOpen !== ''){
                 const toggle = !sections.value[index].isOpen;
                 that.$set(sections.value[index], 'isOpen', toggle);
             } else {
                 that.$set(sections.value[index], 'isOpen', true);
             }
          }
      };

      const toggleItem = (sectionIndex, itemIndex) => {
            const toggle = !sections.value[sectionIndex].items[itemIndex].isOpen;
            that.$set(sections.value[sectionIndex].items[itemIndex], 'isOpen', toggle);
      };

      const showTooltip = (event, text) => {
            if (!openSidebar.value) { 
                const rect = event.target.getBoundingClientRect();
                let top = rect.top;
                let left = rect.right + 10;

                // Adjust if near the right edge
                if (left + 200 > window.innerWidth) {  // Assuming tooltip width is 200px
                    left = rect.left - 210;  // Position on the left side
                }

                tooltip.value = {
                    visible: true,
                    text,
                    top, 
                    left
                };
            }
        };


      const hideTooltip = () => {
          tooltip.value.visible = false;
      };

      const expandSidebarAndToggle = (sectionIndex, itemIndex) => {
          toggleSidebar();
          if (!openSidebar.value) {
              openSidebar.value = true;
              setTimeout(() => {
                toggleItem(sectionIndex, itemIndex);
              }, 300);
          } else {
              toggleItem(sectionIndex, itemIndex);
          }
      };

      // Track group headers to prevent duplicates
      const updateGroupHeader = (title) => {
          previousGroupHeader.value = title;
          return true;
      };

      onMounted(() => {
          loadSidebarStateFromLocalStorage(); // Load from localStorage when component mounts
          fetchNavigationConfig(); // Fetch the navigation config when the component is mounted
          window.addEventListener("resize", updateSidebarState);
      });

      onUnmounted(() => {
          window.removeEventListener("resize", updateSidebarState);
      });

      return {
          openSidebar,
          sections,
          tooltip,
          toggleSidebar,
          toggleSection,
          toggleItem,
          showTooltip,
          hideTooltip,
          expandSidebarAndToggle,
          handleItemClick,
          hasAccess,
          shouldShowItem,
          windowWidth,
          sectionStyles,
          linkStyles,
          nestedLinkStyles,
          previousGroupHeader,
          updateGroupHeader
      };
  }
};
</script>  

<style lang="scss" scoped>
button.material-symbols-outlined {
    transition: transform 0.3s ease; /* Apply smooth transition for transform */
}
.rotate-180 {
    transform: rotate(180deg); /* Rotate the element 180 degrees */
    transition: transform 0.3s ease; /* Optional: adds a smooth transition */
}
</style>
