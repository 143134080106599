<template>
  <!-- <form id="domo-form" @submit.prevent="submitForm">
    <input type="hidden" name="embedToken" v-model="embedToken">
  </form> -->
  <v-row class="full-height-row">
        <v-col cols="12" class="full-height-col">
          <LoadingSpinner
              class="loading-spinner pt-15"
              :loading="showLoader"
              :color="'#70A300'"
              :size="'150px'"
            />
            <!-- eslint-disable-next-line max-len  -->
            <iframe id="iframe" src="" title="Mechanic Job Postings Trends" @load="handleLoadStart"></iframe>
          </v-col>
      </v-row>
</template>

<script>
// import { mapGetters } from 'vuex';
import LoadingSpinner from '../StyledComponents/LoadingSpinner.vue';

export default {
  name: 'Domo',
  components: { LoadingSpinner },
  data() {
    return {
      embedToken: '',
      showLoader: true,
    };
  },
  async beforeCreate() {
    await fetch(`${process.env.VUE_APP_BACKEND_URL}/strategize_frontend/domo/authenticate?embed_id=nx3KY`, {
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }).then(async (res) => {
      const data = await res.json();
      this.embedToken = data.result;
      this.reqListener();
    });
  },
  mounted() {
    const pageValue = {
      link: {
        title: this.competitionPageSelected,
        linkId: `Job Postings ${this.competitionPageSelected}`,
        section: this.competitionPageSelected,
      },
    };
    const lastPageViewed = JSON.stringify(
      {
        title: 'competition',
        linkId: 'Job Posting Trends Mechanic',
        section: 'mechanic',
      },
    );
    sessionStorage.setItem('lastPageViewed', lastPageViewed);
    this.$store.dispatch('global/addPageLinkHighlightAndNavLinkBorder', pageValue);
  },
  // computed: {
  //   ...mapGetters({
  //     competitionPageSelected: `global/getDriverOrMechanicData`,
  //   }),
  // },
  methods: {
    handleLoadStart() {
      setTimeout(() => {
        this.showLoader = false;
      }, 1000);
    },
    reqListener() {
      const iframe = document.getElementById('iframe');
      const embedURL = `https://public.domo.com/embed/pages/nx3KY`;
      const src = this.getGeneratedPageURL(this.embedToken, embedURL);
      iframe.srcdoc = src;

      const supportsSrcdoc = ('srcdoc' in document.createElement('iframe'));
      if (!supportsSrcdoc) {
        iframe.dataset.src = src;
        // eslint-disable-next-line no-script-url
        const js = 'javascript: window.frameElement.getAttribute("data-src")';
        iframe.setAttribute('src', js);
        iframe.contentWindow.location = js;
      }
    },
    getGeneratedPageURL(embedToken, embedUrl) {
      const source = `${'<html>\n'
          + '   <body>\n'
          + '        <form id="form" action="'}${embedUrl}" method="post">\n`
          + `        <input type="hidden" name="embedToken" value="${embedToken}">\n`
          + `        </form>\n`
          + `        <script>\n`
          + `        document.getElementById("form").submit();\n`
          // eslint-disable-next-line no-useless-escape
          + `        <\/script>\n`
          + `    </body>\n`
          + `</html>\n`;

      return source;
    },
  },
};
</script>

<style lang="scss" scoped>
.full-height-row {
  height: 100vh; /* Ensure the row takes full viewport height */
}

.full-height-col {
  height: 100%; /* Ensure the column takes the full height of its parent row */
}

.full-height-iframe {
  width: 100%;
  height: 100%; /* Make iframe take 100% of the v-col height */
  border: none; /* Optional: remove the border around the iframe */
}
iframe {
  width: 100%;
  height: 100vh;
  border: none; /* Optional: Removes the border around the iframe */
}
</style>
