<template>
  <v-app>
    <div id="driver-report-form" class="pa-15 pt-10 fill-height"></div>
  </v-app>
</template>

<script>
import { createWidget } from '@typeform/embed';
import '@typeform/embed/build/css/widget.css';

export default {
  name: 'RequestDriverReport',
  mounted() {
    createWidget('RD7z1tOT', { container: document.querySelector('#driver-report-form') });
  },
};
</script>
<style lang="scss">
footer {
  z-index: 1 !important;
}
</style>
